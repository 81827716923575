import React from 'react';
import "./Advantages.css";
import {FaHourglassStart} from "react-icons/fa";
import {BsQuestionLg} from "react-icons/bs";
import {FaCoins} from "react-icons/fa";
import {IoMdCheckmarkCircleOutline} from "react-icons/io"

const Advantages = () => {
    return (
            <div className='advantages' >            
                <div className='advantages-1'>
                    <h1>Advantages</h1>
                    <p>We listen to our customers and work with them to improve the user experience of our platform.</p>
                </div>
                <div className='advantages-2'>
                    <div className='advantage-2-1'>
                        <div className='advantage'>
                            <div className='advantage-icon'>
                                <FaHourglassStart className='advantage-icons'/>
                            </div>
                            <div>
                                <h3>Smooth Start</h3>
                                <p>Without a visit to the office, you can setup your account in less than 5 minutes.</p>
                            </div>
                        </div>
                        <div className='advantage'>
                            <div className='advantage-icon'>
                                <BsQuestionLg className='advantage-icons'/>
                            </div>
                            <div>
                                <h3>24/7 Support</h3>
                                <p>Our support team is always available to answer questions and resolve any issues.</p>
                            </div>
                        </div>
                    </div>
                    <div className='advantage-2-2'>
                        <div className='advantage'>
                            <div className='advantage-icon'>
                                <FaCoins className='advantage-icons'/>
                            </div>
                            <div>
                                <h3>Low commisions</h3>
                                <p>We give you the best rate we can for any kind of transaction, no extra fees. </p>
                            </div>
                        </div>
                        <div className='advantage'>
                            <div className='advantage-icon'>
                                <IoMdCheckmarkCircleOutline className='advantage-icons'/>
                            </div>
                            <div>
                                <h3>Invest any Amount</h3>
                                <p>You don't have to have large sums to start investing, start small.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Advantages;