import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
	getGlobalState,
	useGlobalState,
	setGlobalState,
} from "../../store/index";
import "../UserDashboard/Deposit/Deposit.css";
import btcLogo from "../../assets/btc.png";
import { MdCopyAll } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";
import QRCode from "qrcode";

const CreateProject = () => {
	const [createModal] = useGlobalState("createModal");
	const [price, setPrice] = useState(getGlobalState("price"));
	const [address, setAddress] = useState("");
	const [qr, setQr] = useState("");
	const [count, setCount] = useState(0);

	const increaseCount = () => {
		let newCount = count + 1;
		setCount(newCount);
	};

	const onClose = () => {
		setGlobalState("createModal", "scale-0");
		if (count < 4) {
			setTimeout(() => {
				setGlobalState("createModal", "scale-100");
				increaseCount();
			}, 5000);
		} else {
			setCount(0);
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const GenerateQRCode = () => {
		QRCode.toDataURL(
			address,
			{
				width: 800,
				margin: 2,
				color: {
					dark: "#2684fe",
					light: "#ffffff00",
				},
			},
			(err, url) => {
				// if (err) return console.error(err)
				// console.log(url)
				setQr(url);
			}
		);
	};

	// eslint-disable-next-line no-extend-native
	Number.prototype.toLocaleFixed = function (n) {
		return this.toLocaleString(undefined, {
			minimumFractionDigits: n,
			maximumFractionDigits: n,
		});
	};

	useEffect(() => {
		try {
			const getAddress = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getaddress",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setAddress(data.address);
				}
				GenerateQRCode();
			};
			getAddress();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [address, GenerateQRCode]);

	//toastify for Address copy
	const showToastMessage = () => {
		toast.success("Copied.", {
			position: toast.POSITION.TOP_RIGHT,
			className: "toast-message",
		});
	};

	useEffect(() => {
		let timerID = setInterval(() => tick(), 1000);

		return () => clearInterval(timerID);
	});

	function tick() {
		setPrice(getGlobalState("price"));
	}

	return (
		<div
			className={`z-10 fixed top-0 left-0 w-screen h-screen flex
    items-center justify-center bg-black bg-opacity-50
    transform transition-transform duration-300 ${createModal}`}
		>
			<div className="create-project shadow-xl shadow-black rounded-xl w-11/12 md:w-2/5 h-7/12 p-6">
				<div className="flex justify-between items-center pb-2 mb-2 border-b-2 border-white">
					<h1 className="font-semibold">Important Notice</h1>
					<button
						onClick={onClose}
						type="button"
						className="border-0 bg-transparent focus:outline-none"
					>
						<FaTimes />
					</button>
				</div>

				<div className="flex justify-between items-center"></div>
				<div className="deposit-2-1">
					<h1 className="font-semibold">Tax and Withdrawal Processing Fee</h1>
					<h3>Amount: ${price && price.toLocaleFixed()} </h3>
					<h3>Deposit is only through BTC payment for now.</h3>
					<p>Average Arrival time: 10 min</p>
				</div>
				<div className="deposit-2-2">
					<h4>BTC Address</h4>
					<div className="deposit-img">
						<img src={qr} alt="qrcode" />
					</div>
					<p className="flex items-center justify-center gap-1 mb-3">
						<span className="address-text">{address}</span>
						<span>
							<CopyToClipboard text={address} onCopy={showToastMessage}>
								<MdCopyAll className="copy-icon" />
							</CopyToClipboard>
						</span>
					</p>
				</div>
				<div className="deposit-2-3">
					<div className="deposit-2-3-text">
						<h5>Send only BTC to this address.</h5>
						<p>
							Sending coin or token other than BTC to <br /> this address would
							result in loss of deposit.
						</p>
					</div>
					<div className="btclogo-img">
						<img src={btcLogo} alt="btcLogo" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateProject;
