import React from "react";
import Faq from "react-faq-component";
import "./Faqs.css"

const data = {
    title: "FAQ",
    rows: [
        {
            title: "What are the risks?",
            content:
            "Investing in cryptoassets involves significant risk and can result in the loss of your invested capital. You should not invest more than you can afford to lose and should ensure that you fully understand the risks involved. It is your responsibility to ascertain whether you are permitted to use our services based on the legal requirements in your country of residence.",
        },
        {
            title: "What are the fees?",
            content: `We have a fully transparent fee structure and you’ll always know exactly what you are paying before making a transaction.`,
        },
        {
            title: "What currencies can I use to make deposits?",
            content: "You can use only BTC and Debit Cards.",
        },
        {
            title: "How long does it take my funds to be allocated into my account?",
            content: `All fiat deposits can take up to 48 hours to reflect. Crypto deposits usually take around 60 minutes to reflect, but can take longer.`,
        },
        {
            title: "When can I make a withdrawal?",
            content: `You can make withdrawal from your Investment Wallet anytime.`,
        },
        {
            title: "Do you have a mobile app?",
            content: `Mobile app development is in progess...`,
        }
    ],
};

const styles = {
    bgColor: "",
    titleTextColor: "#2684FE",
    rowTitleColor: "#000",
    rowContentColor: 'grey',
    arrowColor: "#2684FE",
    rowTitleTextSize: "large",
    titleTextSize: "4rem"
};

const config = {
    animate: true,
    tabFocus: true,
    expandIcon: "+",
    collapseIcon: "-",
};


const Faqs = () => {

    return (
        <div className="faqs-wrapper">
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    )
}

export default Faqs ;