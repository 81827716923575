import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Coin from "./Coin";
import "./Buy.css";
import { AiFillLock } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { BsCreditCard2Front } from "react-icons/bs";
import { FaApplePay } from "react-icons/fa";
import { RiLuggageDepositLine } from "react-icons/ri";
import { toast } from "react-toastify";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";

const Buy = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [buySection, setBuySection] = useState(false);

	const depositHandler = () => {
		setBuySection(!buySection);
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//toastify for Download Buttons
	const showToastMessage = () => {
		toast.info("Coming soon...", {
			position: toast.POSITION.TOP_RIGHT,
			className: "toast-message",
		});
	};

	//Dropdown Card
	const [isToggled3, setIsToggled3] = useState(false);
	const openFeatureToggler3 = () => {
		setIsToggled3(!isToggled3);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//Card
	const cardInitials = {
		name: "",
		address: "",
		amount: "",
		cardNumber: "",
		month: "",
		year: "",
		cvv: "",
	};
	const [card, setCard] = useState(cardInitials);
	function handleCardChange(e) {
		const { name, value } = e.target;

		setCard((values) => ({
			...values,
			[name]: value,
		}));
	}

	const handleCardSubmit = (event) => {
		event.preventDefault();
		// console.log(card);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		const randomNo = getRandomNumberBetween(1000000000000, 9999999999999);

		//toastify for Card Submit
		const showToastMessage = () => {
			toast.error(`txid: ${randomNo} - err-failed.`, {
				position: toast.POSITION.TOP_RIGHT,
				className: "toast-message",
			});
		};
		showToastMessage();

		setTimeout(() => {
			setCard(cardInitials);
		}, 1000);
	};

	const [coins, setCoins] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(() => {
		axios
			.get(
				"https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false"
			)
			.then((res) => {
				setCoins(res.data);
				// console.log(res.data)
			})
			.catch((error) => console.log(error));
	}, []);

	const handleChange = (e) => {
		setSearch(e.target.value);
	};

	const filteredCoins = coins.filter((coin) =>
		coin.name.toLowerCase().includes(search.toLowerCase())
	);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="buy-container">
					<div className="buy-2">
						<div className="buy-2-1">
							<div className="buy-2-1-1">
								<h2 className="buybalance">
									{" "}
									Click on Coin to Buy and Choose Payment Method.
								</h2>
							</div>
							{buySection && (
								<div>
									<div className="buy-2-1-2">
										<div className="buy-section" onClick={showToastMessage}>
											<div className="buy-section-1">
												<FaApplePay className="withdraw-icon applepay" />
												<h5>Buy with Apple Pay</h5>
												<div className="buy-icon">
													{<IoIosArrowDown className="withdraw-icon" />}
												</div>
											</div>
										</div>
										<div
											className="buy-section"
											style={{ height: isToggled3 ? "max-content" : "80px" }}
										>
											<div className="buy-section-1">
												<BsCreditCard2Front className="withdraw-icon" />
												<h5>Buy with Card</h5>
												<div className="buy-icon" onClick={openFeatureToggler3}>
													{!isToggled3 ? (
														<IoIosArrowDown className="withdraw-icon" />
													) : (
														<IoIosArrowUp className="withdraw-icon" />
													)}
												</div>
											</div>
											<div
												className={`toggle-menu ${isToggled3 ? "active" : ""}`}
											>
												<form onSubmit={handleCardSubmit}>
													<label>Name on Card</label>
													<input
														name="name"
														value={card.name}
														placeholder="Enter card holder name"
														onChange={handleCardChange}
														type="text"
														autoComplete="off"
														required
													/>

													<label>Address</label>
													<input
														name="address"
														value={card.address}
														placeholder="Enter address"
														onChange={handleCardChange}
														type="text"
														autoComplete="off"
														required
													/>
													<label htmlFor="amount">Amount</label>
													<input
														name="amount"
														value={card.amount}
														onChange={handleCardChange}
														placeholder="Enter amount to withdraw"
														type="number"
														min="1"
														max="1000000000000"
														step="0.01"
														autoComplete="off"
														required
													/>

													<label>Card Number</label>
													<input
														name="cardNumber"
														value={card.cardNumber}
														placeholder="Enter card number"
														onChange={handleCardChange}
														type="text"
														pattern="[0-9]{13,16}"
														autoComplete="off"
														required
													/>

													<label>Expiry Date</label>
													<div className="expiry-container">
														<input
															name="month"
															value={card.month}
															onChange={handleCardChange}
															type="number"
															placeholder="mm"
															autoComplete="off"
															min="1"
															max="12"
															required
															className="expiry-input"
														/>
														<input
															name="year"
															value={card.year}
															placeholder="yy"
															onChange={handleCardChange}
															type="number"
															autoComplete="off"
															min="00"
															max="30"
															required
															className="expiry-input"
														/>
														<input
															name="cvv"
															value={card.cvv}
															placeholder="cvv"
															onChange={handleCardChange}
															type="number"
															autoComplete="off"
															min="100"
															max="999"
															required
															className="cvv-input"
														/>
													</div>
													<button type="submit">
														{loading2 ? (
															<ScaleLoader
																className="loader-icon"
																color={"rgb(230, 232, 235)"}
																loading={loading2}
																size={50}
																speedMultiplier={1}
															/>
														) : (
															"Submit"
														)}
													</button>
												</form>
											</div>
										</div>
										<div
											className="buy-section"
											style={{ height: isToggled3 ? "max-content" : "80px" }}
											onClick={(e) => navigate("/dashboard/deposit")}
										>
											<div className="buy-section-1">
												<RiLuggageDepositLine className="withdraw-icon" />
												<h5>Buy with BTC Deposit</h5>
												<div className="buy-icon">
													{<IoIosArrowDown className="withdraw-icon" />}
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="buy-4">
							<div className="coin-app">
								<div className="coin-search">
									<form>
										<input
											className="coin-input"
											type="text"
											onChange={handleChange}
											placeholder="Search"
										/>
									</form>
								</div>
								<div className="coin-details">
									{filteredCoins.map((coin) => {
										return (
											<Coin
												key={coin.id}
												name={coin.name}
												price={coin.current_price}
												symbol={coin.symbol}
												image={coin.image}
												priceChange={coin.price_change_percentage_24h}
												onClickFunction={depositHandler}
											/>
										);
									})}
								</div>
							</div>
						</div>
						<div className="buy-3">
							<AiFillLock className="buy-3-icon" />
							<p> All payments are secured and encrypted.</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Buy;
