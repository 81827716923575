import React from 'react';

const Testimonybox = (props) => {
    return (
    <div className="testimonie-box">
        <h2>{props.content.name}</h2>
        <p>{props.content.testimony}</p>
        <div className="rating-container">
            <h3 className='clicked'>{props.content.star}.0</h3>
            <div>
                <span className="material-icons-round  clicked">star</span>
                <span className="material-icons-round  clicked">star</span>
                <span className="material-icons-round  clicked">star</span>
                <span className="material-icons-round  clicked">star</span>
                <span className={`material-icons-round ${props.content.star > 4 ? "clicked" : ""}`}>star</span>
            </div>
        </div>
        <div className='line'></div>
    </div>
    )
}

export default Testimonybox;