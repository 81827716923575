/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef }  from 'react';
import Navbar from "../../components/Navbar/Navbar";
import Hero from "./Hero/Hero";
import Advantages from './Advantages/Advantages';
import Partners from './Partners/Partners';
import Companyvalues from './Companyvalues/Companyvalues';
import Testimonies from './Testimonies/Testimonies';
import Footer from '../../components/Footer/Footer';
import Faqs from './Faqs/Faqs';
import DOTS from 'vanta/dist/vanta.dots.min'


const Indexpage = () => {

    //SETTING UP VANTA.JS ANIMATIONS
    const [vantaEffect, setVantaEffect] = useState(null);
    const myRef = useRef(null);
    useEffect(() => {
        if (!vantaEffect) {
        setVantaEffect(DOTS({
            el: myRef.current,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x349db7,
            color2: 0xffffff,
            backgroundColor: 0xffffff,
            size: 7.10,
            spacing: 26.00,
            showLines: false
        }))
        }
        return () => {
        if (vantaEffect) vantaEffect.destroy()
        }
    }, [vantaEffect])

    return (
        <div>
            <Navbar />
            <Hero /> 
            <a id='values'> </a>
            <Partners />
            <Companyvalues />
            <Advantages />
            <a id='faqs'> </a>
            <div ref={myRef} className="advantages-main-container">
                <Testimonies />
            </div>
                <Faqs />
            <Footer />
        </div>
    )
}

export default Indexpage;