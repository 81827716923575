import React, {useState, useEffect} from 'react'
import btcIcon from "../../../assets/btcicon.png";
import cardIcon from "../../../assets/banks.png";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import {MdDoneAll} from "react-icons/md"
import "./WithdrawHistory.css"

const WithdrawHistory = ({transaction}) => {
    const [mainDate, setMainDate] = useState("")
    
    //Dropdown State Withdrawal History
	const [isToggled, setIsToggled] = useState(false);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
	};

    // eslint-disable-next-line no-extend-native
	Number.prototype.toLocaleFixed = function (n) {
		return this.toLocaleString(undefined, {
			minimumFractionDigits: n,
			maximumFractionDigits: n,
		});
	};

    useEffect(() => {
        const dateString = transaction.date;
        const date = new Date(dateString);
        const timeDiff = new Date() - date;
        const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const today = new Date();
        const timeDiffWords = (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
        if (timeDiffWords < 1 && today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()) {
            setMainDate("Today");
        } else {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const formattedDate = date.toLocaleDateString(undefined, options);

            if (daysAgo === 1) {
                setMainDate("Yesterday");
            } else if (daysAgo > 7){
                setMainDate(`${formattedDate}`);
            } else if (daysAgo < 7){
                setMainDate(`${daysAgo} days ago`);
            }
        }
	}, [transaction.date]);
    
    return (
        <div className={`withdrawal-history-section ${transaction.withdrawStatus !== "pending" && "bg-green-500"}`}>
            <div className="withdrawal-history-section-1" onClick={openFeatureToggler}>
                {transaction.withdrawType === "bank" && (
                    <img
                        src={cardIcon}
                        alt="cardicon"
                        className="history-dashboards-3-icon"
                    />
                )}
                {transaction.withdrawType === "btc" && (
                    <img
                        src={btcIcon}
                        alt="btcicon"
                        className="history-dashboards-3-icon"
                    />
                )}
                <h5>{transaction.withdrawType.toUpperCase()}</h5>
                <div
                    className="withdrawal-history-icon"
                >
                    {!isToggled ? (
                        <IoIosArrowDown className="withdraw-history-icon" />
                    ) : (
                        <IoIosArrowUp className="withdraw-history-icon" />
                    )}
                </div>
            </div>
            <div className={`toggle-menu ${isToggled ? "active" : ""}`}>
                <div className='toggle-menu-text-container'>
                    <h1 className='toggle-menu-text ml-10'> AMOUNT: <span>${transaction.amount.toLocaleFixed(2)}</span></h1>
                    <h1 className='toggle-menu-text ml-10'> DATE: <span>{mainDate}</span></h1>
                </div>
                <div className='toggle-menu-progress'>
                    <ol class={`relative text-gray-500 border-l border-gray-200 ${transaction.withdrawStatus !== "pending" && "border-green-500"}`}>                  
                        <li class="mb-10 ml-6">            
                            <span class="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                <svg aria-hidden="true" class="w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                            </span>
                            <h3 class="font-medium leading-tight pt-2 pl-4 text-green-500">Submitted</h3>
                        </li>
                        <li class="mb-10 ml-6">
                            <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                <svg aria-hidden="true" class="w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                            </span>
                            <h3 class="font-medium leading-tight pt-2 pl-4 text-green-500">In Progress</h3>
                        </li>
                        <li class=" ml-6">
                            { transaction.withdrawStatus !== "pending" ? (
                                <span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                                <svg aria-hidden="true" class="w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                </span>) : (<span class="absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700">
                                <MdDoneAll />
                                </span>)
                            }
                            <h3 class={`font-medium leading-tight pt-2 pl-4 ${transaction.withdrawStatus !== "pending" && "text-green-500"}`}>Completed</h3>
                        </li>
                    </ol>
                </div>
            </div>
		</div>
    )
}

export default WithdrawHistory