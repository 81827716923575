import React, { useEffect, useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
	const navigate = useNavigate();
	const cookies = Cookies.get("jwt");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const verifyUser = async () => {
			if (!cookies) {
				navigate("/signin");
			}
			const { data } = await axios.post(
				"https://mergingtradingllc.com/api/verified",
				{},
				{
					withCredentials: true,
				}
			);
			if (data.success === true) {
				setLoading(true);
			} else {
				setLoading(false);
				Cookies.remove("jwt");
				navigate("/signin");
			}
		};
		verifyUser();
	}, [loading, cookies, navigate]);

	return loading && <Outlet />;
};

export default PrivateRoutes;
