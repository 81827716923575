import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { CgAdd } from "react-icons/cg";
import { HiOutlineCash } from "react-icons/hi";
import { MdRemoveCircleOutline } from "react-icons/md";
import CircleLoader from "react-spinners/CircleLoader";
import { BiTransfer } from "react-icons/bi";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";
import { MdAccountBalanceWallet } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import TradeViewChart from "react-crypto-chart";
import ReactApexChart from "react-apexcharts";

//Transaction 3d Icons
import btcIcon from "../../../assets/btcicon.png";
import cardIcon from "../../../assets/cardIcon.png";
import transferIcon from "../../../assets/transfericon.png";
import withdrawIcon from "../../../assets/withdrawicon.png";

const Dashboards = () => {
	const [loading, setLoading] = useState(false);
	const [deposit, setDeposit] = useState();
	const [transactions, setTransactions] = useState([]);
	const [last7Dates, setLast7Dates] = useState([]);
	const [last7Prices, setlast7Prices] = useState([]);
	const [totalBalance, setTotalBalance] = useState();
	const [fname, setFname] = useState();
	const [currentPage, setCurrentPage] = useState(0);

	const PER_PAGE = 10;
	const offset = currentPage * PER_PAGE;
	const currentPageData = transactions.slice(offset, offset + PER_PAGE);
	const pageCount = Math.ceil(transactions.length / PER_PAGE);

	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	// eslint-disable-next-line no-extend-native
	Number.prototype.toLocaleFixed = function (n) {
		return this.toLocaleString(undefined, {
			minimumFractionDigits: n,
			maximumFractionDigits: n,
		});
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		try {
			const getBalance = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getbalance",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setFname(data.fname);
					setDeposit(data.wallet.deposit.toLocaleFixed(2));
					setTotalBalance(
						(+data.wallet.deposit + +data.wallet.investment).toLocaleFixed(2)
					);
				}
			};
			getBalance();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deposit]);

	useEffect(() => {
		try {
			const getTransactions = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/gettransactions",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					if (data.transactions.length !== 0) {
						let newData = data.transactions.slice(0);
						setTransactions(newData.reverse());
					} else {
						setTransactions(data.transactions);
					}
				}
			};
			getTransactions();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	useEffect(() => {
		try {
			const getTransactions = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getchartdata",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setLast7Dates(data.last7Dates);
					setlast7Prices(data.last7Prices);
				}
			};
			getTransactions();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const containerStyle = {
		minHeight: "300px",
		minWidth: "400px",
		marginBottom: "10px",
	};

	const candleStickConfig = {
		upColor: "#00c176",
		downColor: "#cf304a",
		borderDownColor: "#cf304a",
		borderUpColor: "#00c176",
		wickDownColor: "#838ca1",
		wickUpColor: "#838ca1",
	};

	const histogramConfig = {
		base: 0,
		lineWidth: 2,
		priceFormat: {
			type: "volume",
		},
		overlay: true,
		scaleMargins: {
			top: 0.8,
			bottom: 0,
		},
	};

	const chartLayout = {
		layout: {
			backgroundColor: "#ffffff00",
			textColor: "#fff",
		},
		priceScale: {
			borderColor: "#485c7b",
		},
		timeScale: {
			borderColor: "#485c7b",
			timeVisible: true,
			secondsVisible: false,
		},
	};

	const chartState = {
		series: [
			{
				name: "7 Days Balance",
				data: last7Prices,
			},
		],
		options: {
			chart: {
				height: 300,
				foreColor: "#fff",
				type: "area",
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
			},
			xaxis: {
				type: "datetime",
				categories: last7Dates,
			},
			tooltip: {
				x: {
					format: "dd/MM/yy",
				},
			},
		},
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="dashboards-container">
					<div className="dashboards-2">
						<div className="dashboards-2-1">
							<div className="dashboards-2-1-1">
								<h1 className="dashboardsbalance">Hello {fname},</h1>
								<div className="dashboardsbalance2">
									<div className="dashboardsbalance2-1">
										<div className="dashboardsbalance2-1-1">
											<div className="dashboardsbalance2-1-iconcontainer">
												<MdAccountBalanceWallet className="dashboardsbalance2-1-icon" />
											</div>
											<p>Total Balance</p>
										</div>
										<div className="dashboardsbalance2-1-2">
											<h2>{`$${totalBalance}`}</h2>
										</div>
									</div>
									<div className="dashboardsbalance2-1">
										<div className="dashboardsbalance2-1-1">
											<div className="dashboardsbalance2-1-iconcontainer">
												<MdAccountBalanceWallet className="dashboardsbalance2-1-icon" />
											</div>
											<p>Deposit Balance</p>
										</div>
										<div className="dashboardsbalance2-1-2">
											<h2>{`$${deposit}`}</h2>
										</div>
									</div>
								</div>
								<div className="dashboards-links-container">
									<div className="dashboards-links">
										<Link to="/dashboard/buy" className="dashboards-link">
											<CgAdd className="dashboards-link-icon" />
											Buy
										</Link>
										<Link
											to="/dashboard/withdrawal"
											className="dashboards-link"
										>
											<MdRemoveCircleOutline className="dashboards-link-icon" />
											Withdrawal
										</Link>
										<Link to="/dashboard/deposit" className="dashboards-link">
											<HiOutlineCash className="dashboards-link-icon" />
											Deposit
										</Link>
										<Link to="/dashboard/transfer" className="dashboards-link">
											<BiTransfer className="dashboards-link-icon" />
											Transfer
										</Link>
										<Link to="/dashboard/profile" className="dashboards-link">
											<BsPersonFill className="dashboards-link-icon" />
											Profile
										</Link>
										<Link
											to="/dashboard/virtualcard"
											className="dashboards-link"
										>
											<BsCreditCard2FrontFill className="dashboards-link-icon" />
											Virtual Card
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboards-3">
							<div className="charts-container">
								<div className="charts-container-1">
									<TradeViewChart
										containerStyle={containerStyle}
										candleStickConfig={candleStickConfig}
										histogramConfig={histogramConfig}
										chartLayout={chartLayout}
										pair="BTCUSDT"
									/>
									<p>
										<span> BITCOIN PRICE CHART</span>
										<img
											src={btcIcon}
											alt="btcicon"
											className="dashboards-3-icon-btc"
										/>
									</p>
								</div>
								<div className="charts-container-2">
									<ReactApexChart
										options={chartState.options}
										series={chartState.series}
										type="area"
										height={300}
										width={400}
									/>
									<p> LAST 7 DAYS TOTAL BALANCE CHART</p>
								</div>
							</div>
							<h2>Transactions</h2>
							<div className="dashboards-3-main">
								{currentPageData !== [] ? (
									currentPageData.map((transaction) => {
										return (
											<div
												className="dashboards-3-container"
												key={transaction.id}
											>
												<div className="dashboards-3-1">
													{transaction.trxName === "Deposit" && (
														<img
															src={btcIcon}
															alt="btcicon"
															className="dashboards-3-icon"
														/>
													)}
													{transaction.trxName === "Transfer" && (
														<img
															src={transferIcon}
															alt="transfericon"
															className="dashboards-3-icon"
														/>
													)}
													{transaction.trxName === "Withdrawal" && (
														<img
															src={withdrawIcon}
															alt="withdrawicon"
															className="dashboards-3-icon"
														/>
													)}
													{transaction.trxName === "Virtual Card" && (
														<img
															src={cardIcon}
															alt="cardicon"
															className="dashboards-3-icon"
														/>
													)}
													<div className="dashboards-3-1-1">
														<h5>{transaction.trxName}</h5>
														<p>
															{transaction.date.slice(0, 16).replace("T", "-")}
														</p>
													</div>
												</div>
												<div className="dashboards-3-2">
													<h5>${transaction.amount.toLocaleFixed(2)}</h5>
													<p>{transaction.trxStatus}</p>
												</div>
											</div>
										);
									})
								) : (
									<h3 className="transaction-error">No Transaction's Made.</h3>
								)}
							</div>
							<ReactPaginate
								previousLabel={"← Previous"}
								nextLabel={"Next →"}
								pageCount={pageCount}
								onPageChange={handlePageClick}
								containerClassName={"pagination"}
								previousLinkClassName={"pagination__link"}
								nextLinkClassName={"pagination__link"}
								disabledClassName={"pagination__link--disabled"}
								activeClassName={"pagination__link--active"}
								pageRangeDisplayed={5}
								renderOnZeroPageCount={null}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Dashboards;
