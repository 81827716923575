import React, { useState, useEffect } from "react";
import "./Withdrawal.css";
import { Link } from "react-router-dom";
import { AiOutlineBank } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { BsCreditCard2Front } from "react-icons/bs";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import { MdAccountBalanceWallet } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import { setGlobalState } from "../../../store/index";
import WithdrawHistory from "./WithdrawHistory";

const Withdrawal = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [loading4, setLoading4] = useState(false);
	const [status, setStatus] = useState("");
	const [investment, setInvestment] = useState();
	const [tax, setTax] = useState("");
	const [withdrawals, setWithdrawals] = useState([]);

	useEffect(() => {
		try {
			const getTransactions = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getuserwithdrawals",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					if (data.withdrawals.length !== 0) {
						let newData = data.withdrawals.slice(0);
						setWithdrawals(newData.reverse());
					} else {
						setWithdrawals(data.withdrawals);
					}
				}
			};
			getTransactions();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClick = (amount) => {
		const newPrice = amount * (tax / 100);
		setGlobalState("price", newPrice);
		setGlobalState("createModal", "scale-100");
	};

	// eslint-disable-next-line no-extend-native
	Number.prototype.toLocaleFixed = function (n) {
		return this.toLocaleString(undefined, {
			minimumFractionDigits: n,
			maximumFractionDigits: n,
		});
	};

	useEffect(() => {
		try {
			const getStatusAndAmount = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getkycstatus",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setStatus(data.kycStatus);
				}
			};
			getStatusAndAmount();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [status]);

	useEffect(() => {
		try {
			const getTax = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getusertax",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setTax(data.tax);
				}
			};
			getTax();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [tax]);

	useEffect(() => {
		try {
			const getBalance = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getbalanceandplan",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setInvestment(data.wallet.investment.toLocaleFixed(2));
				}
			};
			getBalance();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investment]);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Dropdown State Wallet
	const [isToggled, setIsToggled] = useState(false);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
		setIsToggled2(false);
		setIsToggled3(false);
	};

	//Dropdown State Bank
	const [isToggled2, setIsToggled2] = useState(false);
	const openFeatureToggler2 = () => {
		setIsToggled2(!isToggled2);
		setIsToggled(false);
		setIsToggled3(false);
	};

	//Dropdown Card
	const [isToggled3, setIsToggled3] = useState(false);
	const openFeatureToggler3 = () => {
		setIsToggled3(!isToggled3);
		setIsToggled(false);
		setIsToggled2(false);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//Bank and Wallet input states

	//Wallet
	const walletInitials = { address: "", amount: "" };
	const [wallet, setWallet] = useState(walletInitials);
	const handleWalletChange = (e) => {
		const { name, value } = e.target;

		setWallet((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleWalletSubmit = async (event) => {
		event.preventDefault();
		// console.log(wallet);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const withdrawalSubmit = await axios.post(
				"https://mergingtradingllc.com/api/withdrawalsubmit",
				{
					btcAddress: wallet.address,
					btcAmount: +wallet.amount,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(withdrawalSubmit.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (withdrawalSubmit.data.success === true) {
				setWallet(walletInitials);
				onClick(+wallet.amount);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Bank
	const bankInitials = {
		details: "",
		amount: "",
		accountName: "",
		bankName: "",
		accountNumber: "",
		routingNumber: "",
		currency: "USD",
	};
	const [bank, setBank] = useState(bankInitials);
	const handleBankChange = (e) => {
		const { name, value } = e.target;

		setBank((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleBankSubmit = async (event) => {
		event.preventDefault();
		// console.log(bank);
		setLoading3(true);
		setTimeout(() => {
			setLoading3(false);
		}, 950);

		try {
			const withdrawalSubmit = await axios.post(
				"https://mergingtradingllc.com/api/withdrawalsubmit",
				{
					bankAmount: +bank.amount,
					details: bank.details,
					accountName: bank.accountName,
					bankName: bank.bankName,
					accountNumber: +bank.accountNumber,
					routingNumber: +bank.routingNumber,
					currency: bank.currency,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(withdrawalSubmit.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (withdrawalSubmit.data.success === true) {
				setBank(bankInitials);
				onClick(+bank.amount);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Card
	const cardInitials = {
		name: "",
		address: "",
		amount: "",
		cardNumber: "",
		month: "",
		year: "",
		cvv: "",
	};
	const [card, setCard] = useState(cardInitials);
	function handleCardChange(e) {
		const { name, value } = e.target;

		setCard((values) => ({
			...values,
			[name]: value,
		}));
	}

	const handleCardSubmit = (event) => {
		event.preventDefault();
		// console.log(card);
		setLoading4(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		const randomNo = getRandomNumberBetween(1000000000000, 9999999999999);

		//toastify for Card Submit
		const showToastMessage = () => {
			toast.error(`txid: ${randomNo} - err-failed.`, {
				position: toast.POSITION.TOP_RIGHT,
				className: "toast-message",
			});
		};
		showToastMessage();

		setTimeout(() => {
			setCard(cardInitials);
		}, 1000);
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="withdrawal-container">
					<div className="withdrawal-2">
						<div className="withdrawal-2-1">
							<div className="withdrawal-2-1-1">
								<div className="withdrawal-2-1-1">
									<div className="withdrawalbalance2">
										<div className="withdrawalbalance2-1">
											<div className="withdrawalbalance2-1-1">
												<div className="withdrawalbalance2-1-iconcontainer">
													<MdAccountBalanceWallet className="withdrawalbalance2-1-icon" />
												</div>
												<p>Withdrawal Balance</p>
											</div>
											<div className="withdrawalbalance2-1-2">
												<h2>${investment}</h2>
											</div>
										</div>
									</div>
								</div>
								<div className="verified-kyc-container">
									<div className="verified-kyc">
										<p>
											<span>{status}</span>
											<span>
												{status !== "Verified" ? (
													<IoMdClose className="withdrawal-2-1-icon" />
												) : (
													<GiCheckMark className="withdrawal-2-1-icon2" />
												)}
											</span>
										</p>
									</div>
									{status !== "Verified" && (
										<div className="verified-kyc">
											Verify Account
											<Link className="kyc-link" to="/dashboard/kyc">
												Verify Now
											</Link>
										</div>
									)}
								</div>
							</div>
							<div className="withdrawal-2-1-2">
								<div className="withdrawal-section">
									<div className="withdrawal-section-1">
										<FaWallet className="withdraw-icon" />
										<h5>Withdraw to Wallet</h5>
										<div
											className="withdrawal-icon"
											onClick={openFeatureToggler}
										>
											{!isToggled ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled ? "active" : ""}`}>
										<form onSubmit={handleWalletSubmit}>
											<label>Address</label>
											<input
												name="address"
												value={wallet.address}
												onChange={handleWalletChange}
												placeholder="Enter BTC wallet address"
												type="text"
												autoComplete="off"
												required
											/>
											<label>Amount</label>
											<input
												name="amount"
												value={wallet.amount}
												onChange={handleWalletChange}
												placeholder="Enter amount to withdraw"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<button type="submit">
												{loading2 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading2}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Submit"
												)}
											</button>
										</form>
									</div>
								</div>
								<div className="withdrawal-section">
									<div className="withdrawal-section-1">
										<AiOutlineBank className="withdraw-icon" />
										<h5>Withdraw to Bank</h5>
										<div
											className="withdrawal-icon"
											onClick={openFeatureToggler2}
										>
											{!isToggled2 ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled2 ? "active" : ""}`}>
										<form className="bankForm" onSubmit={handleBankSubmit}>
											<label>Account Name</label>
											<input
												name="accountName"
												value={bank.accountName}
												onChange={handleBankChange}
												placeholder="Enter Account Name"
												type="text"
												autoComplete="off"
												required
											/>
											<label>Bank Name</label>
											<input
												name="bankName"
												value={bank.bankName}
												onChange={handleBankChange}
												placeholder="Enter Bank Name"
												type="text"
												autoComplete="off"
												required
											/>
											<label>Account Number</label>
											<input
												name="accountNumber"
												value={bank.accountNumber}
												onChange={handleBankChange}
												placeholder="Enter Account Number"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<label>Routing Number</label>
											<input
												name="routingNumber"
												value={bank.routingNumber}
												onChange={handleBankChange}
												placeholder="Enter Routing Number"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<textarea
												name="details"
												placeholder=" Enter other details..."
												value={bank.details}
												onChange={handleBankChange}
											/>
											<label>Amount</label>
											<input
												name="amount"
												value={bank.amount}
												onChange={handleBankChange}
												placeholder="Enter amount to withdraw"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<label>
												Choose Currency
												<select
													value={bank.currency}
													id="currency"
													name="currency"
													onChange={handleBankChange}
												>
													<option value="USD">USD</option>
													<option value="GBP">GBP</option>
													<option value="EUR">EUR</option>
													<option value="CAD">CAD</option>
												</select>
											</label>
											<button type="submit">
												{loading3 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading3}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Submit"
												)}
											</button>
										</form>
									</div>
								</div>
								<div className="withdrawal-section">
									<div className="withdrawal-section-1">
										<BsCreditCard2Front className="withdraw-icon" />
										<h5>Withdraw to Card</h5>
										<div
											className="withdrawal-icon"
											onClick={openFeatureToggler3}
										>
											{!isToggled3 ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled3 ? "active" : ""}`}>
										<form onSubmit={handleCardSubmit}>
											<label>Name on Card</label>
											<input
												name="name"
												value={card.name}
												placeholder="Enter card holder name"
												onChange={handleCardChange}
												type="text"
												autoComplete="off"
												required
											/>

											<label>Address</label>
											<input
												name="address"
												value={card.address}
												placeholder="Enter address"
												onChange={handleCardChange}
												type="text"
												autoComplete="off"
												required
											/>
											<label htmlFor="amount">Amount</label>
											<input
												name="amount"
												value={card.amount}
												onChange={handleCardChange}
												placeholder="Enter amount to withdraw"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>

											<label>Card Number</label>
											<input
												name="cardNumber"
												value={card.cardNumber}
												placeholder="Enter card number"
												onChange={handleCardChange}
												type="text"
												pattern="[0-9]{13,16}"
												autoComplete="off"
												required
											/>

											<label>Expiry Date</label>
											<div className="expiry-container">
												<input
													name="month"
													value={card.month}
													onChange={handleCardChange}
													type="number"
													placeholder="mm"
													autoComplete="off"
													min="1"
													max="12"
													required
													className="expiry-input"
												/>
												<input
													name="year"
													value={card.year}
													placeholder="yy"
													onChange={handleCardChange}
													type="number"
													autoComplete="off"
													min="00"
													max="30"
													required
													className="expiry-input"
												/>
												<input
													name="cvv"
													value={card.cvv}
													placeholder="cvv"
													onChange={handleCardChange}
													type="number"
													autoComplete="off"
													min="100"
													max="999"
													required
													className="cvv-input"
												/>
											</div>
											<button type="submit">
												{loading4 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading4}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Submit"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						<h2 className="withdrawal-2-1-h2">Withdrawals</h2>
							<div className="withdrawals-3-main">
								{withdrawals !== [] ? (
									withdrawals.map((transaction) => {
										return (
											<WithdrawHistory transaction={transaction}/>
										);
									})
								) : (
									<h3 className="transaction-error">No Transaction's Made.</h3>
								)}
							</div>
							
						<div className="withdrawal-3">
							<p>**All withdrawals are processed manually.</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Withdrawal;
