import React from 'react';
import Resetpassword from './Resetpassword';
import Navbar from '../../components/LoginNavbar/LoginNavbar';

const indexpage = () => {
  return (
    <>
      <Navbar />
      <Resetpassword />
    </>
  )
}

export default indexpage;