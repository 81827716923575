import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useGlobalState, setGlobalState } from "../../store/index";
import axios from "axios";
import { Link } from "react-router-dom";
import { RiNotification3Line } from "react-icons/ri";
import "../UserDashboard/Deposit/Deposit.css";

const CreateProject = () => {
	const [notifyModal] = useGlobalState("notifyModal");
	const [unread, setUnread] = useState("");

	useEffect(() => {
		if (unread > 0) {
			setGlobalState("notifyModal", "scale-100");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unread]);

	useEffect(() => {
		try {
			const getStatus = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getnewnotifications",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setUnread(data.totalUnread);
				}
			};
			getStatus();
		} catch (err) { }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClose = () => {
		setGlobalState("notifyModal", "scale-0");
	};

	return (
		<div
			className={`z-10 fixed top-0 left-0 w-screen h-screen flex
    items-center justify-center bg-black bg-opacity-50
    transform transition-transform duration-300 ${notifyModal}`}
		>
			<div className="create-project shadow-xl shadow-black rounded-xl w-11/12 md:w-2/5 h-7/12 p-6 border-2 border-white">
				<div className="flex justify-between items-center pb-2 mb-2 border-b-2 border-white">
					<h1 className="font-semibold">Important Notice</h1>
					<button
						onClick={onClose}
						type="button"
						className="border-0 bg-transparent focus:outline-none"
					>
						<FaTimes />
					</button>
				</div>

				<div className="flex-col justify-center items-center">
					<div className="deposit-2-1 items-center justify-center w-[100] gap-1">
						<h3>You have a New Notification</h3>
						<h3>Click the Button Below.</h3>
						<Link
							to="/dashboard/notification"
							className="notify-link"
							color="white"
							onClick={onClose}
						>
							<RiNotification3Line className="notify-link-icon" />
							Notification
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateProject;
