import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./Signup.css";
import heroImg from "../../assets/signup.png";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import Cookies from "js-cookie";

const BasicForm = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const navigate = useNavigate();
	const cookies = Cookies.get("jwt");

	useEffect(() => {
		const verifyUser = async () => {
			if (!cookies) {
				navigate("/signup");
			}
			const { data } = await axios.post(
				"https://mergingtradingllc.com/api/verified",
				{},
				{
					withCredentials: true,
				}
			);
			if (data.success === true) {
				navigate("/dashboard/home");
			}
		};
		verifyUser();
	}, [loading, cookies, navigate]);

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const signUpUser = await axios.post(
				"https://mergingtradingllc.com/api/signup",
				{
					fname: values.fname,
					lname: values.lname,
					email: values.email,
					phonenumber: values.phonenumber,
					password: values.password,
					promo: values.promo,
				}
			);

			const showToastMessage = () => {
				toast.info(signUpUser.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (signUpUser.data.success === true) {
				actions.resetForm();
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(4, 7);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
	// min 6 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

	const numberRules =
		/^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[456789]\d{9}|(\d[ -]?){10}\d$/;
	// phone Number Regex

	const basicSchema = yup.object().shape({
		fname: yup
			.string()
			.min(2, "Name must be at least 2 characters long")
			.required("Required"),
		lname: yup
			.string()
			.min(2, "Name must be at least 2 characters long")
			.required("Required"),
		promo: yup.string(),
		phonenumber: yup
			.string()
			.min(5)
			.matches(numberRules, { message: "Input valid number" })
			.required("Required"),
		email: yup
			.string()
			.email("Please enter a valid email")
			.required("Required"),
		password: yup
			.string()
			.min(5)
			.matches(passwordRules, { message: "Please create a stronger password" })
			.required("Required"),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match")
			.required("Required"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			fname: "",
			lname: "",
			phonenumber: "",
			email: "",
			password: "",
			confirmPassword: "",
			promo: "",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	// console.log(errors);

	return (
		<>
			{loading ? (
				<div className="signup-loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="signup-wrapper">
					<form onSubmit={handleSubmit} autoComplete="off">
						<div className="signup-heading">
							<h2>Sign Up</h2>
							<p>Create a new account within minutes.</p>
						</div>

						<label htmlFor="fname">First Name</label>
						<input
							id="fname"
							type="string"
							placeholder="Enter your first name"
							value={values.fname}
							onChange={handleChange}
							onBlur={handleBlur}
							className={errors.fname && touched.fname ? "input-error" : ""}
						/>
						{errors.fname && touched.fname && (
							<p className="error">{errors.fname}</p>
						)}

						<label htmlFor="lname">Last Name</label>
						<input
							id="lname"
							type="string"
							placeholder="Enter your last name"
							value={values.lname}
							onChange={handleChange}
							onBlur={handleBlur}
							className={errors.lname && touched.lname ? "input-error" : ""}
						/>
						{errors.lname && touched.lname && (
							<p className="error">{errors.lname}</p>
						)}

						<label htmlFor="phonenumber">Phone Number</label>
						<input
							id="phonenumber"
							type="tel"
							placeholder="Enter your phone number"
							value={values.phonenumber}
							onChange={handleChange}
							onBlur={handleBlur}
							className={
								errors.phonenumber && touched.phonenumber ? "input-error" : ""
							}
						/>
						{errors.phonenumber && touched.phonenumber && (
							<p className="error">{errors.phonenumber}</p>
						)}

						<label htmlFor="email">Email</label>
						<input
							id="email"
							type="email"
							placeholder="Enter your email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							className={errors.email && touched.email ? "input-error" : ""}
						/>
						{errors.email && touched.email && (
							<p className="error">{errors.email}</p>
						)}

						<label htmlFor="password">Password</label>
						<input
							id="password"
							type="password"
							placeholder="Enter your password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							className={
								errors.password && touched.password ? "input-error" : ""
							}
						/>
						{errors.password && touched.password && (
							<p className="error">{errors.password}</p>
						)}

						<label htmlFor="confirmPassword">Confirm Password</label>
						<input
							id="confirmPassword"
							type="password"
							placeholder="Confirm password"
							value={values.confirmPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							className={
								errors.confirmPassword && touched.confirmPassword
									? "input-error"
									: ""
							}
						/>
						{errors.confirmPassword && touched.confirmPassword && (
							<p className="error">{errors.confirmPassword}</p>
						)}

						<label htmlFor="lname">Promo Code</label>
						<input
							id="promo"
							type="string"
							placeholder="Enter promo code (optional)"
							value={values.promo}
							onChange={handleChange}
							onBlur={handleBlur}
							className={errors.promo && touched.promo ? "input-error" : ""}
						/>
						{errors.promo && touched.promo && (
							<p className="error">{errors.promo}</p>
						)}

						<button disabled={isSubmitting} type="submit">
							{loading2 ? (
								<ScaleLoader
									className="loader-icon"
									color={"rgb(255, 255, 255)"}
									loading={loading2}
									size={50}
									speedMultiplier={1}
								/>
							) : (
								"Submit"
							)}
						</button>

						<p>
							Already have an account. <Link to="/signin"> Login here </Link>
						</p>
					</form>
					<div className="sign-hero-2">
						<img src={heroImg} alt="hero-section-img" />
					</div>
				</div>
			)}
		</>
	);
};
export default BasicForm;
