import React, { useState, useEffect} from 'react'
import ReactApexChart from "react-apexcharts";

const InvestmentChart = ({plan}) => {
    const [planPrice, setPlanPrice] = useState([]);
	const [planDate, setPlanDate] = useState([]);

    const updatePlanDetails = async () => {
        let newDate = []
        let newAmount = []

		newDate.push("2023-06-01T00:00:00.000Z")
		newAmount.push(0)
    
        await plan.totalEarnedArray.map( async (earned) => {
            newDate.push(earned.date)
            newAmount.push(earned.amount.toFixed(2))
            return ""
        })

        setPlanDate(newDate)
        setPlanPrice(newAmount)
    }
    useEffect(() => {
		updatePlanDetails()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const chartState = {
		series: [
			{
				name: "Ongoing Plan",
				data: planPrice,
			},
		],
		options: {
			chart: {
				height: 200,
				foreColor: "#fff",
				type: "area",
				toolbar: {
					show: false,
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
					show: true,
					curve: 'smooth',
					lineCap: 'butt',
				},
			grid: {
				show: false,
			},
			xaxis: {
				type: "datetime",
				categories: planDate,
			},
			tooltip: {
				x: {
					format: "dd/MM/yy",
				},
			},
		},
	};

    return (
            <ReactApexChart
                options={chartState.options}
                series={chartState.series}
                type="area"
                height={150}
                width={"100%"}
            />
    )
}

export default InvestmentChart