import React, { useState, useEffect } from "react";
import "./Cardrequest.css";
import cardImg from "../../../assets/virtual_card.png";
import { GiCheckMark } from "react-icons/gi";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";
import generator from "creditcard-generator";

const Cardrequest = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [genNumber, setGenNumber] = useState("");
	const [expNumber, setExpNumber] = useState("");
	const [expMonth, setExpMonth] = useState("");
	const [cvv, setCVV] = useState("");
	const [userDetails, setUserDetails] = useState("");

	const loadingHandler = async () => {
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 1950);

		try {
			const transferBalance = await axios.post(
				"https://mergingtradingllc.com/api/cardrequest",
				{},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(transferBalance.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const currentDate = new Date();
		currentDate.getMonth() < 10
			? setExpMonth("0" + currentDate.getMonth())
			: setExpMonth(currentDate.getMonth());

		setExpNumber(`${expMonth}-27`);
		setGenNumber(generator.GenCC("VISA"));
		setCVV(Math.floor(Math.random() * (999 - 100 + 1) + 100));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expMonth]);

	useEffect(() => {
		try {
			const getAddress = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getprofile",
					{},
					{
						withCredentials: true,
					}
				);
				if (data.success === true) {
					setUserDetails(data.user);
				}
			};
			getAddress();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, []);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="cardrequest-container">
					<div className="cardrequest-2">
						<div className="cardrequest-2-img">
							<h5 className="cardname">{`${userDetails.lname} ${userDetails.fname}`}</h5>
							<h4 className="cardnumber">
								{genNumber.toString().replace(/\d{4}(?=.)/g, "$& ")}
							</h4>
							<h6 className="cardcvv">{cvv}</h6>
							<h6 className="cardexpiry">{expNumber}</h6>
							<img src={cardImg} alt="debitcard" />
						</div>
						<div className="cardrequest-2-text">
							<h3>
								The all New Merging Trader <br /> Virtual Debit Card
							</h3>
							<ul>
								<li>
									<GiCheckMark className="cardrequest-icon" /> Use it anywhere
									in the world
								</li>
								<li>
									<GiCheckMark className="cardrequest-icon" /> Transparent and
									Secure
								</li>
								<li>
									<GiCheckMark className="cardrequest-icon" /> Zero transaction
									fees
								</li>
								<li>
									<GiCheckMark className="cardrequest-icon" /> No Hidden Charges
								</li>
							</ul>
							<button className="cardrequest-button" onClick={loadingHandler}>
								{loading2 ? (
									<ScaleLoader
										className="loader-icon"
										color={"rgb(255, 255, 255)"}
										loading={loading2}
										size={50}
										speedMultiplier={1}
									/>
								) : (
									"Get Card for $50"
								)}
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Cardrequest;
