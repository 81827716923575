import React, {useState} from 'react';
import "./Footer.css";
import { Link } from "react-router-dom";
import logoImg from "../../assets/logo2.png";
import Playstore from "../../assets/playstore.png";
import Appstore from "../../assets/appstore.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import { toast } from 'react-toastify';

const Footer = () => {
    //toastify for Download Buttons
    const showToastMessage = () => {
        toast.info('Coming soon...', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message'
        });
    };
    //toastify for Newsletter
    const showNewsMessage = () => {
        toast.success('Email Added.', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message'
        });
    };

    //email validation for newsletter input
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    //Regex for email validation
    function isEmail(emailAdress) {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (emailAdress.match(regex)) 
        return true; 
    else 
        return false; 
    }

    //handle input change
    const handleChange = event => {
        if (isEmail(event.target.value)) {
            setError(true);
        } else {
            setError(false);
        }
        setMessage(event.target.value);
    };
    
    //handle toastify
    const handleToastify = () => {
        if (error) {
            showNewsMessage();
            setMessage("");
            setError("false");
        }
    }

    //handle enter key press 
    const handleEnterKey = (event) => {
        if (error === true) {
            if (event.key === 'Enter') {
                showNewsMessage();
                setMessage("");
                setError("false");
            } 
        }
    }

    //handle scroll to top 
    const scrollhandler = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    return (
        <div className='footer' id='footer'>
            <div class="custom-shape-divider-top-1675361871">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>
            <div className="container-1">
                <div className="footer-1">
                    <div className='footer-1-1'>
                        <div className='footer-img'><Link to="/"><img src={logoImg} alt="merging-trading-logo" /></Link></div>
                        <p>Merging Trader is an investment company, whose team is working to make money from the volatility of cryptocurrencies and offer great return to our clients.</p>
                        <p>Make passive income from investing with us.</p>
                        <div className='download-btns'>
                        <div className='download-btn'>
                            <img src={Playstore} alt="playstore" onClick={showToastMessage}/>
                        </div>
                        <div className='download-btn'>
                            <img src={Appstore} alt="appstore" onClick={showToastMessage}/>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer-2">
                    <div className="footer-1-1">
                        <ul>
                            <li className='footer-2-header'>Links</li>
                            <li><Link to="/dashboard/home" onClick={scrollhandler}>Dashboard</Link></li>
                            <li><Link to="/dashboard/investment" onClick={scrollhandler}>Investment</Link></li>
                            <li><a href='#values'>Values</a></li>
                            <li><a href="#faqs">FAQ</a></li>
                        </ul>
                    </div>
                    <div className="footer-2-2">
                        <ul> 
                            <li className='footer-2-header'>Get started</li>
                            <Link to="/signin" onClick={scrollhandler} className='login-btn'>Login</Link>
                            <Link to="/signup" onClick={scrollhandler} className='signup-btn'>Sign Up</Link>
                        </ul>
                    </div>
                    <div className="footer-2-3">
                        <h1 className='footer-2-header'>Newsletter</h1>
                        <div className="mail">
                            <input type="text" placeholder=" Enter your email" value={message} onChange={handleChange} onKeyPress={handleEnterKey}/>
                            <button className="button" disabled={!error} onClick={handleToastify}> <HiArrowNarrowRight className='button-arrow'/> </button>
					</div>
                        <p>Sign Up for our Newsletter</p>
                    </div>
                </div>
            </div>
            <div className="container-2">
                <div className='container-2-2'>
                    <p>Copyright © 2023.</p>
                    <p>Merging Trader All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer