import React, { useState, useEffect } from "react";
import "./Withdrawrequest.css";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import { toast } from "react-toastify";

import ReactPaginate from "react-paginate";
import Withdrawuser from "./Withdrawuser";

const Withdrawrequest = () => {
	const [loading, setLoading] = useState(false);
	const [userDetails, setUserDetails] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [search, setSearch] = useState("");

	const userDetails2 = userDetails.filter((userDetails) =>
		userDetails.email.toLowerCase().includes(search.toLowerCase())
	);

	const PER_PAGE = 10;
	const offset = currentPage * PER_PAGE;
	const currentPageData = userDetails2.slice(offset, offset + PER_PAGE);
	const pageCount = Math.ceil(userDetails2.length / PER_PAGE);

	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	const handleChanges = (e) => {
		setSearch(e.target.value);
	};

	useEffect(() => {
		try {
			const getUsers = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/admin/allwithdrawals",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setUserDetails(data.newTotal);
				}
			};
			getUsers();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [userDetails]);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="withdrawrequest-container">
					<div className="withdrawrequest-2">
						<div className="coin-search">
							<form>
								<input
									className="coin-input"
									type="text"
									onChange={handleChanges}
									placeholder="Search"
								/>
							</form>
						</div>
						<div className="withdrawrequest-2-2">
							{currentPageData !== []
								? currentPageData.map((withdraw) => {
									return <Withdrawuser withdraw={withdraw} />;
								})
								: ""}

							<ReactPaginate
								previousLabel={"← Previous"}
								nextLabel={"Next →"}
								pageCount={pageCount}
								onPageChange={handlePageClick}
								containerClassName={"pagination"}
								previousLinkClassName={"pagination__link"}
								nextLinkClassName={"pagination__link"}
								disabledClassName={"pagination__link--disabled"}
								activeClassName={"pagination__link--active"}
								pageRangeDisplayed={5}
								renderOnZeroPageCount={null}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Withdrawrequest;
