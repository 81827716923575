import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdCopyAll } from "react-icons/md";
import ScaleLoader from "react-spinners/ScaleLoader";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";

const Withdrawuser = (props) => {
	//Dropdown State Wallet
	const [isToggled, setIsToggled] = useState(false);
	const [loading2, setLoading2] = useState(false);

	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
	};

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const submitKyc = await axios.post(
				"https://mergingtradingllc.com/api/admin/withdrawalsubmission",
				{
					email: values.email,
					title: values.title,
					subject: values.subject,
					submitType: values.submitType,
					withdrawalId: values.withdrawalId,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(submitKyc.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (submitKyc.data.success === true) {
				// actions.resetForm();
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	const basicSchema = yup.object().shape({
		title: yup.string().min(2, "Name must be at least 2 characters long"),
		subject: yup.string().min(2, "Name must be at least 2 characters long"),
		submitType: yup.string().min(2, "Name must be at least 2 characters long"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			title: "",
			subject: "",
			submitType: "accept",
			withdrawalId: "",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	//toastify for copy
	const showToastMessage = () => {
		toast.success("Copied.", {
			position: toast.POSITION.TOP_RIGHT,
			className: "toast-message",
		});
	};

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<div className="withdrawrequest-heading">
				<h2>Withdrawal Request</h2>
			</div>
			<div className="withdrawrequest-details">
				<h5 className="flex gap-1 mb-3">
					Email:
					<span>
						{props.withdraw.email}
						<CopyToClipboard
							text={props.withdraw.email}
							onCopy={showToastMessage}
						>
							<MdCopyAll className="copy-icon-admin" />
						</CopyToClipboard>
					</span>
				</h5>
				<h5>
					Withdrawal Id:{" "}
					<span>
						{props.withdraw.request._id}
						<CopyToClipboard
							text={props.withdraw.request._id}
							onCopy={showToastMessage}
						>
							<MdCopyAll className="copy-icon-admin" />
						</CopyToClipboard>
					</span>
				</h5>
				<h5>
					Withdraw Type:{" "}
					<span>{props.withdraw.request.withdrawType.toUpperCase()}</span>
				</h5>
				{props.withdraw.request.withdrawType === "btc" ? (
					<h5>
						BTC Address: <span>{props.withdraw.request.btcAddress}</span>
					</h5>
				) : (
					""
				)}
				<h5>
					Amount: <span>${props.withdraw.request.amount}</span>
				</h5>
				{props.withdraw.request.withdrawType === "bank" ? (
					<>
						<h5>
							Account Name: <span>{props.withdraw.request.accountName}</span>
						</h5>
						<h5>
							BankName: <span>{props.withdraw.request.bankName}</span>
						</h5>
						<h5>
							Account Number:{" "}
							<span>{props.withdraw.request.accountNumber}</span>
						</h5>
						<h5>
							Routing Number:{" "}
							<span>{props.withdraw.request.routingNumber}</span>
						</h5>
						<h5>
							Other Details: <span>{props.withdraw.request.otherDetails}</span>
						</h5>
						<h5>
							Currency: <span>{props.withdraw.request.currency}</span>
						</h5>
					</>
				) : (
					""
				)}
				<div className="withdrawrequest-details-circle">
					<div className="withdrawrequest-details-circle1"></div>
				</div>
			</div>
			<div className="line-divider"></div>
			<div className="add-container">
				<h5 className="add-mesage-text">Add Message</h5>
				<div className="deposit-icon" onClick={openFeatureToggler}>
					{!isToggled ? (
						<IoIosArrowDown className="withdraw-icon" />
					) : (
						<IoIosArrowUp className="withdraw-icon" />
					)}
				</div>
			</div>
			<div className={`text-container ${isToggled ? "active" : ""}`}>
				<label htmlFor="title">User Email</label>
				<input
					id="email"
					type="email"
					placeholder="Copy the user email above into here"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					className={errors.email && touched.email ? "input-error" : ""}
				/>
				{errors.email && touched.email && (
					<p className="error">{errors.email}</p>
				)}
				<label htmlFor="title">Withdrawal Id</label>
				<input
					id="withdrawalId"
					type="string"
					placeholder="Copy the user withdrawal Id above into here"
					value={values.withdrawalId}
					onChange={handleChange}
					onBlur={handleBlur}
					className={
						errors.withdrawalId && touched.withdrawalId ? "input-error" : ""
					}
				/>
				{errors.withdrawalId && touched.withdrawalId && (
					<p className="error">{errors.withdrawalId}</p>
				)}
				<label htmlFor="title">Title</label>
				<input
					id="title"
					type="string"
					placeholder="Enter the title"
					value={values.title}
					onChange={handleChange}
					onBlur={handleBlur}
					className={errors.title && touched.title ? "input-error" : ""}
				/>
				{errors.title && touched.title && (
					<p className="error">{errors.title}</p>
				)}
				<label htmlFor="subject">Subject</label>
				<textarea
					id="subject"
					type="string"
					placeholder="Enter the subject"
					value={values.subject}
					onChange={handleChange}
					onBlur={handleBlur}
					className={
						errors.subject && touched.subject
							? "input-error input-length"
							: "input-length"
					}
				/>
				{errors.subject && touched.subject && (
					<p className="error">{errors.subject}</p>
				)}
				<div className="label-container">
					<label>
						Accept or Decline
						<select
							value={values.submitType}
							id="submitType"
							name="submitType"
							onChange={handleChange}
						>
							<option value="accept">Accept</option>
							<option value="decline">Decline</option>
						</select>
					</label>
				</div>
				{errors.title && touched.title && (
					<p className="error">{errors.title}</p>
				)}
				<button disabled={isSubmitting} type="submit">
					{loading2 ? (
						<ScaleLoader
							className="loader-icon"
							color={"rgb(255, 255, 255)"}
							loading={loading2}
							size={50}
							speedMultiplier={1}
						/>
					) : (
						"Submit"
					)}
				</button>
			</div>
		</form>
	);
};

export default Withdrawuser;
