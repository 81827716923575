import React, { useState , useEffect } from "react";
import "./Testimonies.css";
import Testimonybox from './Testimonybox';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {Keyboard, Autoplay, Pagination, Navigation } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Testimonies = () => {

    // Testimonies content
    const testimonies = [
        {   key: 1,
            testimony: "I was a bit skeptical about this website when I used it but I was glad to know that it is truly reliable.",
            name: "Debra D. Miller",
            star: "5"
        },
        {   key: 2,
            testimony: "Life has become better for me and my Family since I started investing with Merging Trader.",
            name: "Jerome A. Fitz",
            star: "5"
        },
        {   key: 3,
            testimony: "I have been earning with Merging Trader for a while now and I am glad I signed up.",
            name: "Nathan McVicars",
            star: "4"
        },
        {   key: 4,
            testimony: "It has been a cool way to earn extra pasive income, kudos.",
            name: "Georgia Sanderson",
            star: "5"
        },
        {   key: 5,
            testimony: "I have been using Merging Trader for a while now and its been a smooth ride.",
            name: "Jacqueline J. Benedict",
            star: "4"
        }
    ]
    
    //Setting Inner width on every window change
    const [dimensions, setDimensions] = useState({ 
        height: window.innerHeight,
        width: window.innerWidth
    })
    
    useEffect(() => {
        function handleResize() {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }
        window.addEventListener('resize', handleResize)
        return _ => {
        window.removeEventListener('resize', handleResize)
    }
    })


    // Setting Slides View on Inner Width Change.
    const [slidesView, setSlidesView] = useState(3);
    
    useEffect(()=>{
        slidesViewHandler()
    },[dimensions.width])

    function slidesViewHandler() {
        if (window.innerWidth < 853){
            setSlidesView(1)
            return
        }
        setSlidesView(3)
    }

    return (
        <div className='testimonies' id="testimonies">
            <div className="testimonie-1">
                <h1>Testimonies</h1>
            </div>

            <Swiper
                modules={[Keyboard, Autoplay, Pagination, Navigation]}
                slidesPerView={slidesView}
                // pagination={{clickable: true}}
                loop={true}
                effect={"fade"}
                mousewheel={true}
                direction={"horizontal"}
                keyboard={{
                    enabled: true,
                }}
                centeredSlides={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                }}
                className="mySwiper"
            >
                {
                    testimonies.map(content => {
                        return(
                            <SwiperSlide className="swiperSlide" key={content.key}>
                                <Testimonybox content={content} key={content.key}/>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
}

export default Testimonies;