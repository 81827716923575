import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useFormik } from "formik";
import * as yup from "yup";
import { MdCopyAll } from "react-icons/md";
import ScaleLoader from "react-spinners/ScaleLoader";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";

const KycComponent = (props) => {
	const [loading2, setLoading2] = useState(false);
	const [isToggled, setIsToggled] = useState(false);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
	};

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const submitKyc = await axios.post(
				"https://mergingtradingllc.com/api/admin/kycsubmission",
				{
					email: values.email,
					title: values.title,
					subject: values.subject,
					submitType: values.submitType,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(submitKyc.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (submitKyc.data.success === true) {
				actions.resetForm();
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	const basicSchema = yup.object().shape({
		title: yup
			.string()
			.min(2, "Name must be at least 2 characters long")
			.required("Required"),
		email: yup
			.string()
			.email("Please enter a valid email")
			.required("Required"),
		subject: yup
			.string()
			.min(2, "Name must be at least 2 characters long")
			.required("Required"),
		submitType: yup
			.string()
			.min(2, "Name must be at least 2 characters long")
			.required("Required"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			title: "",
			subject: "",
			email: "",
			submitType: "accept",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	// console.log(errors);

	//toastify for copy
	const showToastMessage = () => {
		toast.success("Copied.", {
			position: toast.POSITION.TOP_RIGHT,
			className: "toast-message",
		});
	};

	return (
		<form onSubmit={handleSubmit} autoComplete="off">
			<div className="kycVerification-heading">
				<h2>KYC Verification</h2>
			</div>
			<div className="kycVerification-details">
				<h5>
					First Name: <span>{props.user.fname}</span>
				</h5>
				<h5>
					Last Name: <span>{props.user.lname}</span>
				</h5>
				<h5>
					Email:{" "}
					<span>
						{props.user.email}
						<CopyToClipboard text={props.user.email} onCopy={showToastMessage}>
							<MdCopyAll className="copy-icon-admin" />
						</CopyToClipboard>
					</span>
				</h5>
				<h5>
					Address: <span>{props.user.address}</span>
				</h5>
				<h5>
					City: <span>{props.user.city}</span>
				</h5>
				<h5>
					State <span>{props.user.state}</span>
				</h5>
				<h5>
					ZipCode: <span>{props.user.zipCode}</span>
				</h5>
				<h5>
					SSN: <span>{props.user.ssn}</span>
				</h5>
				<h5>Front ID Card</h5>
				<div className="idcard-img">
					<img src={props.user.fIdCard} alt="frontID" />
				</div>
				<h5>Back ID Card</h5>
				<div className="idcard-img">
					<img src={props.user.bIdCard} alt="backID" />
				</div>
				<div className="kycVerification-details-circle">
					<div className="kycVerification-details-circle1"></div>
				</div>
			</div>
			<div className="line-divider"></div>
			<div className="add-container">
				<h5 className="add-mesage-text">Add Message</h5>
				<div className="deposit-icon" onClick={openFeatureToggler}>
					{!isToggled ? (
						<IoIosArrowDown className="withdraw-icon" />
					) : (
						<IoIosArrowUp className="withdraw-icon" />
					)}
				</div>
			</div>
			<div className={`text-container ${isToggled ? "active" : ""}`}>
				<label htmlFor="title">Email</label>
				<input
					id="email"
					type="email"
					placeholder="Copy the user email above into here"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					className={errors.email && touched.email ? "input-error" : ""}
				/>
				{errors.email && touched.email && (
					<p className="error">{errors.email}</p>
				)}
				<label htmlFor="title">Title</label>
				<input
					id="title"
					type="string"
					placeholder="Enter the title"
					value={values.title}
					onChange={handleChange}
					onBlur={handleBlur}
					className={errors.title && touched.title ? "input-error" : ""}
				/>
				{errors.title && touched.title && (
					<p className="error">{errors.title}</p>
				)}
				<label htmlFor="subject">Subject</label>
				<textarea
					id="subject"
					type="string"
					placeholder="Enter the subject"
					value={values.subject}
					onChange={handleChange}
					onBlur={handleBlur}
					className={
						errors.subject && touched.subject
							? "input-error input-length"
							: "input-length"
					}
				/>
				{errors.subject && touched.subject && (
					<p className="error">{errors.subject}</p>
				)}
				<div className="label-container">
					<label>
						Accept or Decline
						<select
							value={values.submitType}
							id="submitType"
							name="submitType"
							onChange={handleChange}
						>
							<option value="accept">Accept</option>
							<option value="decline">Decline</option>
						</select>
					</label>
				</div>
				{errors.title && touched.title && (
					<p className="error">{errors.title}</p>
				)}
				<button disabled={isSubmitting} type="submit">
					{loading2 ? (
						<ScaleLoader
							className="loader-icon"
							color={"rgb(255, 255, 255)"}
							loading={loading2}
							size={50}
							speedMultiplier={1}
						/>
					) : (
						"Submit"
					)}
				</button>
			</div>
		</form>
	);
};

export default KycComponent;
