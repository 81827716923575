import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "./Profile.css";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import { BsPersonFill } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";

const Profile = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [userDetails, setUserDetails] = useState("");

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		try {
			const getAddress = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getprofile",
					{},
					{
						withCredentials: true,
					}
				);
				if (data.success === true) {
					setUserDetails(data.user);
				}
			};
			getAddress();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, []);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const signUpUser = await axios.post(
				"https://mergingtradingllc.com/api/updateprofile",
				{
					fname: values.fname,
					lname: values.lname,
					phonenumber: values.phonenumber,
					password: values.password,
				},
				{
					withCredentials: true,
				}
			);

			setLoading2(false);
			const showToastMessage = () => {
				toast.info(signUpUser.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (signUpUser.data.success === true) {
				actions.resetForm();
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
	// min 6 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

	const numberRules =
		/^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[456789]\d{9}|(\d[ -]?){10}\d$/;
	// phone Number Regex

	const basicSchema = yup.object().shape({
		fname: yup.string().min(2, "Name must be at least 2 characters long"),
		lname: yup.string().min(2, "Name must be at least 2 characters long"),
		phonenumber: yup
			.string()
			.min(5)
			.matches(numberRules, { message: "Input valid number" }),
		password: yup
			.string()
			.min(5)
			.matches(passwordRules, { message: "Please create a stronger password" }),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			fname: "",
			lname: "",
			phonenumber: "",
			password: "",
			confirmPassword: "",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	// console.log(errors);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="profile-container">
					<div className="profile-2">
						<div className="profile-2-2">
							<form onSubmit={handleSubmit} autoComplete="off">
								<div className="profile-heading">
									<div className="profile-icon-container">
										<BsPersonFill className="profile-icon" />
									</div>
									<h2>Edit Profile</h2>
								</div>
								<div className="profile-details">
									<h5>
										First Name: <span>{userDetails.fname}</span>
									</h5>
									<h5>
										Last Name: <span>{userDetails.lname}</span>
									</h5>
									<h5>
										Phone Number: <span>+{userDetails.phonenumber}</span>
									</h5>
									<h5>
										Email: <span>{userDetails.email}</span>
									</h5>
									<div className="profile-details-circle">
										<div className="profile-details-circle1">
											<div className="profile-details-circle2"></div>
										</div>
									</div>
								</div>
								<div className="line-divider"></div>
								<label htmlFor="fname">First Name</label>
								<input
									id="fname"
									type="string"
									placeholder="Enter your first name"
									value={values.fname}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.fname && touched.fname ? "input-error" : ""}
								/>
								{errors.fname && touched.fname && (
									<p className="error">{errors.fname}</p>
								)}

								<label htmlFor="lname">Last Name</label>
								<input
									id="lname"
									type="string"
									placeholder="Enter your last name"
									value={values.lname}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.lname && touched.lname ? "input-error" : ""}
								/>
								{errors.lname && touched.lname && (
									<p className="error">{errors.lname}</p>
								)}

								<label htmlFor="phonenumber">Phone Number</label>
								<input
									id="phonenumber"
									type="tel"
									placeholder="Enter your phone number"
									value={values.phonenumber}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.phonenumber && touched.phonenumber
											? "input-error"
											: ""
									}
								/>
								{errors.phonenumber && touched.phonenumber && (
									<p className="error">{errors.phonenumber}</p>
								)}

								<label htmlFor="password">Password</label>
								<input
									id="password"
									type="password"
									placeholder="Enter your password"
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.password && touched.password ? "input-error" : ""
									}
								/>
								{errors.password && touched.password && (
									<p className="error">{errors.password}</p>
								)}

								<label htmlFor="confirmPassword">Confirm Password</label>
								<input
									id="confirmPassword"
									type="password"
									placeholder="Confirm password"
									value={values.confirmPassword}
									onChange={handleChange}
									onBlur={handleBlur}
									className={
										errors.confirmPassword && touched.confirmPassword
											? "input-error"
											: ""
									}
								/>
								{errors.confirmPassword && touched.confirmPassword && (
									<p className="error">{errors.confirmPassword}</p>
								)}

								<button disabled={isSubmitting} type="submit">
									{loading2 ? (
										<ScaleLoader
											className="loader-icon"
											color={"rgb(255, 255, 255)"}
											loading={loading2}
											size={50}
											speedMultiplier={1}
										/>
									) : (
										"Update"
									)}
								</button>
							</form>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Profile;
