import React, { useState } from "react";
import { IoMdCheckmarkCircle } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";

const PlanTopup = (props) => {
	//Update Amount
	const amountInitials = { amount: "" };
	const [amount, setAmount] = useState(amountInitials);
	const handleAmountChange = (e) => {
		const { name, value } = e.target;
		setAmount((values) => ({
			...values,
			[name]: value,
		}));
	};

	const handlePlanUpdate = async (event, id) => {
		event.preventDefault();
	};

	async function updatePlanHandler(id) {
		try {
			const updatePlan = await axios.post(
				"https://mergingtradingllc.com/api/admin/userupdateongoingplans",
				{
					email: props.userMail,
					amount: amount.amount,
					planId: id,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(updatePlan.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (updatePlan.data.success === true) {
				setAmount(amountInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}
	return (
		<div className="topupplan-4-text" key={props.details.id}>
			<h3>{props.details.planName}</h3>
			<ul>
				<li>
					<IoMdCheckmarkCircle className="topupplan-4-icon" /> Days Left:{" "}
					<span>{props.details.lockTime}</span>
				</li>
				<li>
					<IoMdCheckmarkCircle className="topupplan-4-icon" /> Total Earned:{" "}
					<span>${props.details.totalEarned.toLocaleFixed(2)}</span>{" "}
				</li>
			</ul>
			<form className="emailForm" onSubmit={handlePlanUpdate}>
				<label>Amount</label>
				<input
					name="amount"
					value={amount.amount}
					onChange={handleAmountChange}
					placeholder="Enter amount to add"
					type="text"
					autoComplete="off"
					required
				/>
				<button
					type="submit"
					onClick={() => updatePlanHandler(props.details._id)}
				>
					Add
				</button>
			</form>
		</div>
	);
};

export default PlanTopup;
