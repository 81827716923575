import React, { useState, useEffect } from 'react';
import './AdminNavbar.css';
import { Link } from "react-router-dom";
import logoImg from "../../assets/logo2.png";
import {BiHomeCircle} from "react-icons/bi";
import {BiMenuAltRight} from "react-icons/bi";
import {BsFillMoonFill, BsBrightnessHighFill} from "react-icons/bs";

const DashboardNavbar = (props) => {
  const [isOpened, setIsOpened] = useState(true);
  const [isScroll, setIsScroll] = useState(false);
  
  const openMenuHandler = () => {
    setIsOpened(!isOpened);
  }

  useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 90) {
        setIsOpened(true);
				setIsScroll(true);
			} else {
        setIsScroll(false);
        setIsOpened(true);
			}
		});
	}, []);

   //handle scroll to top 
    const scrollhandler = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

  return (
    <div className={`DashboardNavbar ${isScroll ? "sticky" : ""}`}>
      <div className="wrapper">
        <div className='DashboardNavbar-1'>
          <Link to="/">
            <img src={logoImg} alt="merging-trading-blogo" />
          </Link>
        </div>
        <div className='DashboardNavbar-3'>
            <BiMenuAltRight onClick={props.open2MenuHandler} className='sidebar-open'/>
              <Link to="/" onClick={e => {openMenuHandler(); scrollhandler()}}><BiHomeCircle className="DashboardNavbar-home"/></Link>
            {props.theme === "dark" 
              ? <BsBrightnessHighFill className='navbar-3-icon' onClick={ e => {props.themeSwitch()}}/> 
              : <BsFillMoonFill className='navbar-3-icon' onClick={ e => {props.themeSwitch()}} color="black"/>
            }
          </div>
      </div>
    </div>
  )
}

export default DashboardNavbar