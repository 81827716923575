import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./Kyc.css";
import PreviewImage from "./PreviewImage";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import { IoMdClose } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";

const Kyc = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [status, setStatus] = useState("");

	useEffect(() => {
		try {
			const getStatus = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getkycstatus",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setStatus(data.kycStatus);
				}
			};
			getStatus();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [status]);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const formData = new FormData();
			for (let value in values) {
				formData.append(value, values[value]);
			}
			const kycSubmit = await axios.post(
				"https://mergingtradingllc.com/api/kycsubmit",
				formData,
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(kycSubmit.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (kycSubmit.data.success === true) {
				actions.resetForm();
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const basicSchema = yup.object().shape({
		address: yup
			.string()
			.min(2, "Address must be at least 2 characters long")
			.required("Required"),
		city: yup
			.string()
			.min(2, "City must be at least 2 characters long")
			.required("Required"),
		state: yup
			.string()
			.min(2, "State must be at least 2 characters long")
			.required("Required"),
		zipCode: yup.string().min(5, "Input a valid ZipCode").required("Required"),
		ssn: yup.string().min(5, "Input a valid SSN number").required("Required"),
		fIdCard: yup
			.mixed()
			.required("Required")
			// .test("FILE_SIZE", "Too big!", (value) => value.size < 1024 * 1024)
			.test(
				"FILE_TYPE",
				"Unsupported Format",
				(value) => value && ["image/png", "image/jpeg"].includes(value.type)
			),
		bIdCard: yup
			.mixed()
			.required("Required")
			// .test("FILE_SIZE", "Too big!", (value) => value.size < 1024 * 1024)
			.test(
				"FILE_TYPE",
				"Unsupported Format",
				(value) => value && ["image/png", "image/jpeg"].includes(value.type)
			),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
		setFieldValue,
	} = useFormik({
		initialValues: {
			address: "",
			city: "",
			state: "",
			zipCode: "",
			ssn: "",
			fIdCard: "",
			bIdCard: "",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	// console.log(errors);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="kyc-container">
					<div className="kyc-2">
						<div className="kyc-2-1">
							<h3>Verification Status</h3>
							<div className="kyc-2-1-1">
								<p>{status}</p>
								{status !== "Verified" ? (
									<IoMdClose className="kyc-2-1-icon" />
								) : (
									<GiCheckMark className="kyc-2-1-icon2" />
								)}
							</div>
						</div>
						{status !== "Verified" ? (
							<div className="kyc-2-2">
								<form
									onSubmit={handleSubmit}
									autoComplete="off"
									encType="multipart/form-data"
								>
									<div className="kyc-heading">
										<p>Provide neccessary details to get Verified</p>
									</div>

									<label htmlFor="address">Address</label>
									<input
										id="address"
										type="string"
										placeholder="Enter your address"
										value={values.address}
										onChange={handleChange}
										onBlur={handleBlur}
										className={
											errors.address && touched.address ? "input-error" : ""
										}
									/>
									{errors.address && touched.address && (
										<p className="error">{errors.address}</p>
									)}

									<label htmlFor="city">City</label>
									<input
										id="city"
										type="string"
										placeholder="Enter your city name"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.city && touched.city ? "input-error" : ""}
									/>
									{errors.city && touched.city && (
										<p className="error">{errors.city}</p>
									)}

									<label htmlFor="state">State</label>
									<input
										id="state"
										type="string"
										placeholder="Enter your state name"
										value={values.state}
										onChange={handleChange}
										onBlur={handleBlur}
										className={
											errors.state && touched.state ? "input-error" : ""
										}
									/>
									{errors.state && touched.state && (
										<p className="error">{errors.state}</p>
									)}

									<label htmlFor="zipCode">Zip Code</label>
									<input
										id="zipCode"
										type="string"
										placeholder="Enter your zip code"
										value={values.zipCode}
										onChange={handleChange}
										onBlur={handleBlur}
										className={
											errors.zipCode && touched.zipCode ? "input-error" : ""
										}
									/>
									{errors.zipCode && touched.zipCode && (
										<p className="error">{errors.zipCode}</p>
									)}

									<label htmlFor="ssn">SSN</label>
									<input
										id="ssn"
										type="string"
										placeholder="Enter your SSN No."
										value={values.ssn}
										onChange={handleChange}
										onBlur={handleBlur}
										className={errors.ssn && touched.ssn ? "input-error" : ""}
									/>
									{errors.ssn && touched.ssn && (
										<p className="error">{errors.ssn}</p>
									)}

									<label htmlFor="fIdCard">Front ID</label>
									<input
										name="fIdCard"
										type="file"
										onChange={(e) =>
											setFieldValue("fIdCard", e.target.files[0])
										}
										onBlur={handleBlur}
										className={
											errors.fIdCard && touched.fIdCard ? "input-error" : ""
										}
									/>
									{errors.fIdCard && touched.fIdCard && (
										<p className="error">{errors.fIdCard}</p>
									)}
									{values.fIdCard && <PreviewImage file={values.fIdCard} />}

									<label htmlFor="bIdCard">Back ID</label>
									<input
										name="bIdCard"
										type="file"
										onChange={(e) =>
											setFieldValue("bIdCard", e.target.files[0])
										}
										onBlur={handleBlur}
										className={
											errors.bIdCard && touched.bIdCard ? "input-error" : ""
										}
									/>
									{errors.bIdCard && touched.bIdCard && (
										<p className="error">{errors.bIdCard}</p>
									)}
									{values.bIdCard && <PreviewImage file={values.bIdCard} />}

									<button disabled={isSubmitting} type="submit">
										{loading2 ? (
											<ScaleLoader
												className="loader-icon"
												color={"rgb(0, 0, 0)"}
												loading={loading2}
												size={50}
												speedMultiplier={1}
											/>
										) : (
											"Submit"
										)}
									</button>
								</form>
							</div>
						) : (
							""
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Kyc;
