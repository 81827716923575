import React, { useState, useEffect } from "react";
import "./DashboardNavbar.css";
import { Link } from "react-router-dom";
import logoImg from "../../assets/logo2.png";
import { BiHomeCircle } from "react-icons/bi";
import { RiNotification3Line } from "react-icons/ri";
import { BiMenuAltRight } from "react-icons/bi";
import { BsFillMoonFill, BsBrightnessHighFill } from "react-icons/bs";
import axios from "axios";
import { toast } from "react-toastify";
import { Howl } from "howler";
import sounds from "../../assets/notificationSound.wav";

const DashboardNavbar = (props) => {
	const [isOpened, setIsOpened] = useState(true);
	const [isScroll, setIsScroll] = useState(false);
	const [unread, setUnread] = useState(0);

	const openMenuHandler = () => {
		setIsOpened(!isOpened);
	};

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 90) {
				setIsOpened(true);
				setIsScroll(true);
			} else {
				setIsScroll(false);
				setIsOpened(true);
			}
		});
	}, []);

	useEffect(() => {
		if (unread > 0) {
			new Howl({
				src: [sounds],
				autoplay: true,
				preload: true,
				volume: 0.5,
				onend: function () { },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unread]);

	//handle scroll to top
	const scrollhandler = () => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	};

	useEffect(() => {
		try {
			const getStatus = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getnewnotifications",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setUnread(data.totalUnread);
				}
			};
			getStatus();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={`DashboardNavbar ${isScroll ? "sticky" : ""}`}>
			<div className="wrapper">
				<div className="DashboardNavbar-1">
					<Link to="/">
						<img src={logoImg} alt="merging-trading-blogo" />
					</Link>
				</div>
				<div className="DashboardNavbar-3">
					<BiMenuAltRight
						onClick={props.open2MenuHandler}
						className="sidebar-open"
					/>
					<Link
						to="/dashboard/notification"
						onClick={(e) => {
							openMenuHandler();
							scrollhandler();
						}}
					>
						<RiNotification3Line
							className={
								"DashboardNavbar-home" +
								(unread > 0
									? props.theme === "dark"
										? " blob2"
										: " blob"
									: "")
							}
						/>
					</Link>
					<Link
						to="/"
						onClick={(e) => {
							openMenuHandler();
							scrollhandler();
						}}
					>
						<BiHomeCircle className="DashboardNavbar-home" />
					</Link>
					{props.theme === "dark" ? (
						<BsBrightnessHighFill
							className="navbar-3-icon"
							onClick={(e) => {
								props.themeSwitch();
							}}
						/>
					) : (
						<BsFillMoonFill
							className="navbar-3-icon"
							onClick={(e) => {
								props.themeSwitch();
							}}
							color="black"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default DashboardNavbar;
