import React from 'react';
import "./Companyvalues.css";
import {AiOutlineFileProtect} from "react-icons/ai";
import {AiFillMessage} from "react-icons/ai";
import {BiTimer} from "react-icons/bi";

const Companyvalues = () => {
    return (
        <div className='companyvalues-wrapper'>
            <div className='companyvalues'>
                <div className='companyvalues-1'>
                    <h1 className="companyvalues-title">Our <br/> Company <br/> Values</h1>
                    <div className='companyvalue'>
                        <div className='companyvalues-icon'>
                            <AiOutlineFileProtect className='companyvalues-icons'/>
                        </div>
                        <div>
                            <h3>Professionalism</h3>
                            <p>The strength of our company lies in the Professionalism of each employees in carrying out there job. We also value and develop expertise and business qualities and business qualities.</p>
                        </div>
                    </div>
                </div>
                <div className='companyvalues-2'>
                        <div className='companyvalue'>
                            <div className='companyvalues-icon'>
                                <AiFillMessage className='companyvalues-icons'/>
                            </div>
                            <div>
                                <h3>Trustworthy</h3>
                                <p>We adhere to high ethical standards when dealing with Colleagues, Clients and Partners. Honesty, decency and open communication are the cornerstone.</p>
                            </div>
                        </div>
                        <div className='companyvalue'>
                            <div className='companyvalues-icon'>
                                <BiTimer className='companyvalues-icons'/>
                            </div>
                            <div>
                                <h3>Efficiency</h3>
                                <p>We always strive to achieve high result in work, effectively using all resources neccesary for this.</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Companyvalues