import React, { useState, useEffect } from "react";
import "./SendMail.css";
import { AiOutlineMail } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";

const Sendmail = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Dropdown State One
	const [isToggled2, setIsToggled2] = useState(false);
	const openFeatureToggler2 = () => {
		setIsToggled2(!isToggled2);
		// setIsToggled(false);
	};

	//Dropdown State Many
	const [isToggled, setIsToggled] = useState(false);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
		// setIsToggled(false);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//Send to One
	const oneInitials = { email: "", title: "", subject: "" };
	const [one, setOne] = useState(oneInitials);
	const handleOneChange = (e) => {
		const { name, value } = e.target;
		setOne((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleOneSubmit = async (event) => {
		event.preventDefault();
		// console.log(one);
		setLoading3(true);
		setTimeout(() => {
			setLoading3(false);
		}, 950);

		try {
			const oneEmail = await axios.post(
				"https://mergingtradingllc.com/api/admin/oneuseremail",
				{
					email: one.email,
					title: one.title,
					subject: one.subject,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(oneEmail.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (oneEmail.data.success === true) {
				setOne(oneInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Send to Many
	const manyInitials = { title: "", subject: "" };
	const [many, setMany] = useState(manyInitials);
	const handleManyChange = (e) => {
		const { name, value } = e.target;
		setMany((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleManySubmit = async (event) => {
		event.preventDefault();
		// console.log(many);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);
		try {
			const manyNotification = await axios.post(
				"https://mergingtradingllc.com/api/admin/manyuseremail",
				{
					title: many.title,
					subject: many.subject,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(manyNotification.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (manyNotification.data.success === true) {
				setMany(manyInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="sendmail-container">
					<div className="sendmail-2">
						<div className="sendmail-section">
							<div className="sendmail-section-1">
								<AiOutlineMail className="withdraw-icon" />
								<h5>Send Mail to One</h5>
								<div className="sendmail-icon" onClick={openFeatureToggler2}>
									{!isToggled2 ? (
										<IoIosArrowDown className="withdraw-icon" />
									) : (
										<IoIosArrowUp className="withdraw-icon" />
									)}
								</div>
							</div>
							<div className={`toggle-menu ${isToggled2 ? "active" : ""}`}>
								<form className="emailForm" onSubmit={handleOneSubmit}>
									<label>Email</label>
									<input
										name="email"
										value={one.email}
										onChange={handleOneChange}
										placeholder="Enter Email"
										type="email"
										autoComplete="off"
										required
									/>
									<label>Title</label>
									<input
										name="title"
										value={one.title}
										onChange={handleOneChange}
										placeholder="Enter Title"
										type="text"
										autoComplete="off"
										required
									/>
									<label>Subject</label>
									<textarea
										name="subject"
										placeholder=" Enter subject"
										value={one.subject}
										onChange={handleOneChange}
									/>
									<button type="submit">
										{loading3 ? (
											<ScaleLoader
												className="loader-icon"
												color={"rgb(230, 232, 235)"}
												loading={loading3}
												size={50}
												speedMultiplier={1}
											/>
										) : (
											"Submit"
										)}
									</button>
								</form>
							</div>
						</div>
						<div className="sendmail-section">
							<div className="sendmail-section-1">
								<AiOutlineMail className="withdraw-icon" />
								<h5>Send Mail to All</h5>
								<div className="sendmail-icon" onClick={openFeatureToggler}>
									{!isToggled2 ? (
										<IoIosArrowDown className="withdraw-icon" />
									) : (
										<IoIosArrowUp className="withdraw-icon" />
									)}
								</div>
							</div>
							<div className={`toggle-menu ${isToggled ? "active" : ""}`}>
								<form className="emailForm" onSubmit={handleManySubmit}>
									<label>Title</label>
									<input
										name="title"
										value={many.title}
										onChange={handleManyChange}
										placeholder="Enter Title"
										type="text"
										autoComplete="off"
										required
									/>
									<label>Subject</label>
									<textarea
										name="subject"
										placeholder=" Enter subject"
										value={many.subject}
										onChange={handleManyChange}
									/>
									<button type="submit">
										{loading2 ? (
											<ScaleLoader
												className="loader-icon"
												color={"rgb(230, 232, 235)"}
												loading={loading2}
												size={50}
												speedMultiplier={1}
											/>
										) : (
											"Submit"
										)}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Sendmail;
