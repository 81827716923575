import { createGlobalState } from 'react-hooks-global-state'

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  createModal: 'scale-0',
  notifyModal: 'scale-0',
  price: ""
})

export {
  useGlobalState,
  setGlobalState,
  getGlobalState,
}
