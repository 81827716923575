import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./KycVerification.css";
import CircleLoader from "react-spinners/CircleLoader";
import KycComponent from "./KycComponent";
import ReactPaginate from "react-paginate";

const KycVerification = () => {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState("");
	const [currentPage, setCurrentPage] = useState(0);

	const PER_PAGE = 5;
	const offset = currentPage * PER_PAGE;
	const currentPageData = users.slice(offset, offset + PER_PAGE);
	const pageCount = Math.ceil(users.length / PER_PAGE);

	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	useEffect(() => {
		try {
			const getUsers = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/admin/kycverification",
					{},
					{
						withCredentials: true,
					}
				);
				if (data.success === true) {
					setUsers(data.users);
				}
			};
			getUsers();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, []);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="kycVerification-container">
					<div className="kycVerification-2">
						<div className="kycVerification-2-2">
							{currentPageData !== ""
								? currentPageData.map((user) => {
									return <KycComponent user={user} />;
								})
								: ""}

							<ReactPaginate
								previousLabel={"← Previous"}
								nextLabel={"Next →"}
								pageCount={pageCount}
								onPageChange={handlePageClick}
								containerClassName={"pagination"}
								previousLinkClassName={"pagination__link"}
								nextLinkClassName={"pagination__link"}
								disabledClassName={"pagination__link--disabled"}
								activeClassName={"pagination__link--active"}
								pageRangeDisplayed={5}
								renderOnZeroPageCount={null}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default KycVerification;
