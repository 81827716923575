import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Resetpassword.css";
import heroImg from "../../assets/recovery.png";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";

const BasicForm = () => {
	const navigate = useNavigate();
	const { id, token } = useParams();
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);
		try {
			const link = `https://mergingtradingllc.com/api/reset-password/${id}/${token}`;
			const signUpUser = await axios.post(link, {
				password: values.password,
			});

			const showToastMessage = () => {
				toast.info(signUpUser.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (signUpUser.data.success === true) {
				actions.resetForm();
				setTimeout(() => {
					navigate("/signin");
				}, 1000);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(4, 7);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
	// min 6 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

	const basicSchema = yup.object().shape({
		password: yup
			.string()
			.min(5)
			.matches(passwordRules, { message: "Please create a stronger password" })
			.required("Required"),
		confirmPassword: yup
			.string()
			.oneOf([yup.ref("password"), null], "Passwords must match")
			.required("Required"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			password: "",
			confirmPassword: "",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	// console.log(errors);

	return (
		<>
			{loading ? (
				<div className="signup-loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="resetpassword-wrapper">
					<form onSubmit={handleSubmit} autoComplete="off">
						<div className="resetpassword-heading">
							<h2>Reset Password</h2>
							<p>Input New Password</p>
						</div>

						<label htmlFor="password">Password</label>
						<input
							id="password"
							type="password"
							placeholder="Enter your password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							className={
								errors.password && touched.password ? "input-error" : ""
							}
						/>
						{errors.password && touched.password && (
							<p className="error">{errors.password}</p>
						)}

						<label htmlFor="confirmPassword">Confirm Password</label>
						<input
							id="confirmPassword"
							type="password"
							placeholder="Confirm password"
							value={values.confirmPassword}
							onChange={handleChange}
							onBlur={handleBlur}
							className={
								errors.confirmPassword && touched.confirmPassword
									? "input-error"
									: ""
							}
						/>
						{errors.confirmPassword && touched.confirmPassword && (
							<p className="error">{errors.confirmPassword}</p>
						)}

						<button disabled={isSubmitting} type="submit">
							{loading2 ? (
								<ScaleLoader
									className="loader-icon"
									color={"rgb(255, 255, 255)"}
									loading={loading2}
									size={50}
									speedMultiplier={1}
								/>
							) : (
								"Reset"
							)}
						</button>
					</form>
					<div className="sign-hero-2">
						<img src={heroImg} alt="hero-section-img" />
					</div>
				</div>
			)}
		</>
	);
};
export default BasicForm;
