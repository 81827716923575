import React, { useState, useEffect } from "react";
import "./TopUp.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";
import { MdOutlineAttachMoney } from "react-icons/md";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdCopyAll } from "react-icons/md";

const Topup = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [loading4, setLoading4] = useState(false);
	const [loading5, setLoading5] = useState(false);
	const [loading6, setLoading6] = useState(false);
	const [generatedCode, setGeneratedCode] = useState();

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//Dropdown BuyPlan 
	const [isToggled5, setIsToggled5] = useState(false);
	const openFeatureToggler5 = () => {
		setIsToggled(false);
		setIsToggled(false);
		setIsToggled2(false);
		setIsToggled3(false);
		setIsToggled4(false);
		setIsToggled5(!isToggled5);
	};

	//BuyPlan State Handler
	const buyPlanInitials = { email: "", type: "" };
	const [planStatus, setPlanStatus] = useState(buyPlanInitials);
	const handlePlanStatus = (e) => {
		const { name, value } = e.target;

		setPlanStatus((values) => ({
			...values,
			[name]: value,
		}));
	};

	const handlePlanStatusSubmit = async (event) => {
		event.preventDefault();
		console.log(planStatus);
		setLoading6(true);
		setTimeout(() => {
			setLoading6(false);
		}, 950);

		try {
			const handlePlanStatus = await axios.post(
				"https://mergingtradingllc.com/api/admin/updatebuystatus",
				{
					email: planStatus.email,
					type: planStatus.type,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(handlePlanStatus.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (handlePlanStatus.data.success === true) {
				setWallet(buyPlanInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Dropdown State Topup
	const [isToggled, setIsToggled] = useState(false);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
		setIsToggled2(false);
		setIsToggled3(false);
		setIsToggled4(false);
		setIsToggled5(false);
	};

	//Topup State Handler
	const walletInitials = { amount: "", email: "", type: "deposit" };
	const [wallet, setWallet] = useState(walletInitials);
	const handleWalletChange = (e) => {
		const { name, value } = e.target;

		setWallet((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleWalletSubmit = async (event) => {
		event.preventDefault();
		// console.log(wallet);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const topUpUser = await axios.post(
				"https://mergingtradingllc.com/api/admin/topupuser",
				{
					email: wallet.email,
					amount: +wallet.amount,
					type: wallet.type,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(topUpUser.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (topUpUser.data.success === true) {
				setWallet(walletInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Dropdown State Coupon
	const [isToggled2, setIsToggled2] = useState(false);
	const openFeatureToggler2 = () => {
		setIsToggled(false);
		setIsToggled2(!isToggled2);
		setIsToggled3(false);
		setIsToggled4(false);
		setIsToggled5(false);
	};

	//Coupon State Handler
	const couponInitials = { amount: "" };
	const [coupon, setCoupon] = useState(couponInitials);
	const handleCouponChange = (e) => {
		const { name, value } = e.target;

		setCoupon((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleCouponSubmit = async (event) => {
		event.preventDefault();
		// console.log(coupon);
		setLoading3(true);
		setTimeout(() => {
			setLoading3(false);
		}, 950);

		try {
			const generateCoupon = await axios.post(
				"https://mergingtradingllc.com/api/admin/generatecoupon",
				{
					amount: +coupon.amount,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(generateCoupon.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (generateCoupon.data.success === true) {
				setGeneratedCode(generateCoupon.data.coupon);
				setCoupon(couponInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Dropdown State Tax
	const [isToggled3, setIsToggled3] = useState(false);
	const openFeatureToggler3 = () => {
		setIsToggled(false);
		setIsToggled2(false);
		setIsToggled3(!isToggled3);
		setIsToggled4(false);
		setIsToggled5(false);
	};

	//Tax State Handler
	const taxInitials = { amount: "", email: "" };
	const [tax, setTax] = useState(taxInitials);
	const handleTaxChange = (e) => {
		const { name, value } = e.target;

		setTax((values) => ({
			...values,
			[name]: value,
		}));
	};

	const handleTaxSubmit = async (event) => {
		event.preventDefault();
		// console.log(wallet);
		setLoading4(true);
		setTimeout(() => {
			setLoading4(false);
		}, 950);

		try {
			const changeTax = await axios.post(
				"https://mergingtradingllc.com/api/admin/changetax",
				{
					email: tax.email,
					amount: +tax.amount,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(changeTax.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (changeTax.data.success === true) {
				setTax(taxInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//Dropdown State Profit
	const [isToggled4, setIsToggled4] = useState(false);
	const openFeatureToggler4 = () => {
		setIsToggled(false);
		setIsToggled2(false);
		setIsToggled3(false);
		setIsToggled4(!isToggled4);
		setIsToggled5(false);
	};

	//Profit State Handler
	const profitInitials = { amount: "", email: "" };
	const [profit, setProfit] = useState(profitInitials);
	const handleProfitChange = (e) => {
		const { name, value } = e.target;

		setProfit((values) => ({
			...values,
			[name]: value,
		}));
	};

	const handleProfitSubmit = async (event) => {
		event.preventDefault();
		// console.log(wallet);
		setLoading5(true);
		setTimeout(() => {
			setLoading5(false);
		}, 950);

		try {
			const changeProfit = await axios.post(
				"https://mergingtradingllc.com/api/admin/changeprofit",
				{
					email: profit.email,
					amount: +profit.amount,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(changeProfit.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (changeProfit.data.success === true) {
				setProfit(profitInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//toastify for copy
	const showToastMessage = () => {
		toast.success("Copied.", {
			position: toast.POSITION.TOP_RIGHT,
			className: "toast-message",
		});
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="topup-container">
					<div className="topup-2">
						<div className="topup-2-1">
							<div className="topup-2-1-1">
								<h2 className="topupbalance"> Top-up User Balance</h2>
							</div>
							<div className="topup-2-1-2">
								<div className="topup-section">
									<div className="topup-section-1">
										<BiTransfer className="withdraw-icon" />
										<h5>Top-up User</h5>
										<div className="topup-icon" onClick={openFeatureToggler}>
											{!isToggled ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled ? "active" : ""}`}>
										<form onSubmit={handleWalletSubmit}>
											<label>Email</label>
											<input
												name="email"
												value={wallet.email}
												onChange={handleWalletChange}
												placeholder="Enter email"
												type="email"
												autoComplete="off"
												required
											/>
											<label>Amount</label>
											<input
												name="amount"
												value={wallet.amount}
												onChange={handleWalletChange}
												placeholder="Enter amount to top-up"
												type="number"
												min="-9999999999999"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<div className="label-container">
												<label>
													Wallet Type
													<select
														value={wallet.type}
														id="type"
														name="type"
														onChange={handleWalletChange}
													>
														<option value="deposit">Deposit</option>
														<option value="investment">Investment</option>
														<option value="profit">Profit</option>
														<option value="earned7">Earned (7)</option>
														<option value="earned30">Earned (30)</option>
													</select>
												</label>
											</div>
											<button type="submit">
												{loading2 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading2}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Top-up"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="topup-2-1">
							<div className="topup-2-1-1">
								<h2 className="topupbalance"> Generate Coupon</h2>
							</div>
							<div className="topup-2-1-2">
								<div className="topup-section">
									<div className="topup-section-1">
										<BiTransfer className="withdraw-icon" />
										<h5>Generate Coupon</h5>
										<div className="topup-icon" onClick={openFeatureToggler2}>
											{!isToggled2 ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled2 ? "active" : ""}`}>
										<form onSubmit={handleCouponSubmit}>
											<label>Amount</label>
											<input
												name="amount"
												value={coupon.amount}
												onChange={handleCouponChange}
												placeholder="Enter amount for coupon"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<div className="coupon-container">
												{generatedCode}
												<CopyToClipboard
													text={generatedCode}
													onCopy={showToastMessage}
												>
													<MdCopyAll className="copy-icon-admin" />
												</CopyToClipboard>
											</div>
											<button type="submit">
												{loading3 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading3}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Generate"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="topup-2-1">
							<div className="topup-2-1-1">
								<h2 className="topupbalance"> Update User Tax</h2>
							</div>
							<div className="topup-2-1-2">
								<div className="topup-section">
									<div className="topup-section-1">
										<MdOutlineAttachMoney className="withdraw-icon" />
										<h5>Update User Tax</h5>
										<div className="topup-icon" onClick={openFeatureToggler3}>
											{!isToggled3 ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled3 ? "active" : ""}`}>
										<form onSubmit={handleTaxSubmit}>
											<label>Email</label>
											<input
												name="email"
												value={tax.email}
												onChange={handleTaxChange}
												placeholder="Enter email"
												type="email"
												autoComplete="off"
												required
											/>
											<label>Amount</label>
											<input
												name="amount"
												value={tax.amount}
												onChange={handleTaxChange}
												placeholder="Enter new Tax in percent(%)"
												type="number"
												min="-9999999999999"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<button type="submit">
												{loading4 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading4}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Update Tax"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="topup-2-1">
							<div className="topup-2-1-1">
								<h2 className="topupbalance">
									Update User Close of Profit Chart
								</h2>
							</div>
							<div className="topup-2-1-2">
								<div className="topup-section">
									<div className="topup-section-1">
										<MdOutlineAttachMoney className="withdraw-icon" />
										<h5>Update User Profit Chart</h5>
										<div className="topup-icon" onClick={openFeatureToggler4}>
											{!isToggled3 ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled4 ? "active" : ""}`}>
										<form onSubmit={handleProfitSubmit}>
											<label>Email</label>
											<input
												name="email"
												value={profit.email}
												onChange={handleProfitChange}
												placeholder="Enter email"
												type="email"
												autoComplete="off"
												required
											/>
											<label>Amount</label>
											<input
												name="amount"
												value={profit.amount}
												onChange={handleProfitChange}
												placeholder="Update Close of Profit Chart"
												type="number"
												min="-9999999999999"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<button type="submit">
												{loading5 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading5}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Update"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
						<div className="topup-2-1">
							<div className="topup-2-1-1">
								<h2 className="topupbalance">
									Buy Plan Status
								</h2>
							</div>
							<div className="topup-2-1-2">
								<div className="topup-section">
									<div className="topup-section-1">
										<MdOutlineAttachMoney className="withdraw-icon" />
										<h5>Update Buy Plan</h5>
										<div className="topup-icon" onClick={openFeatureToggler5}>
											{!isToggled5 ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled5 ? "active" : ""}`}>
										<form onSubmit={handlePlanStatusSubmit}>
											<label>Email</label>
											<input
												name="email"
												value={planStatus.email}
												onChange={handlePlanStatus}
												placeholder="Enter email"
												type="email"
												autoComplete="off"
												required
											/>
											<div className="label-container">
												<label>
													Type
													<select
														value={planStatus.type}
														id="type"
														name="type"
														onChange={handlePlanStatus}
													>
														<option value="true">True</option>
														<option value="false">False</option>
													</select>
												</label>
											</div>
											<button type="submit">
												{loading6 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading6}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Update"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Topup;
