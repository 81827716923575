import React, { useState, useEffect } from "react";
import "./Transfer.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";

const Transfer = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//Dropdown State Wallet
	const [isToggled, setIsToggled] = useState(false);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
	};

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//Wallet State Handler
	const walletInitials = { amount: "" };
	const [wallet, setWallet] = useState(walletInitials);
	const handleWalletChange = (e) => {
		const { name, value } = e.target;

		setWallet((values) => ({
			...values,
			[name]: value,
		}));
	};
	const handleWalletSubmit = async (event) => {
		event.preventDefault();

		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const transferBalance = await axios.post(
				"https://mergingtradingllc.com/api/transferbalance",
				{
					wallet: +wallet.amount,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(transferBalance.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (transferBalance.data.success === true) {
				setWallet(walletInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="transfer-container">
					<div className="transfer-2">
						<div className="transfer-2-1">
							<div className="transfer-2-1-1">
								<h2 className="transferbalance"> Transfer here: </h2>
							</div>
							<div className="transfer-2-1-2">
								<div className="transfer-section">
									<div className="transfer-section-1">
										<BiTransfer className="withdraw-icon" />
										<h5>Transfer to Investment Wallet</h5>
										<div className="transfer-icon" onClick={openFeatureToggler}>
											{!isToggled ? (
												<IoIosArrowDown className="withdraw-icon" />
											) : (
												<IoIosArrowUp className="withdraw-icon" />
											)}
										</div>
									</div>
									<div className={`toggle-menu ${isToggled ? "active" : ""}`}>
										<form onSubmit={handleWalletSubmit}>
											<label>Amount</label>
											<input
												name="amount"
												value={wallet.amount}
												onChange={handleWalletChange}
												placeholder="Enter amount to transfer"
												type="number"
												min="1"
												max="1000000000000"
												step="0.01"
												autoComplete="off"
												required
											/>
											<button type="submit">
												{loading2 ? (
													<ScaleLoader
														className="loader-icon"
														color={"rgb(230, 232, 235)"}
														loading={loading2}
														size={50}
														speedMultiplier={1}
													/>
												) : (
													"Transfer"
												)}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Transfer;
