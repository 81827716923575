import React from 'react';
import partner1 from '../../../assets/partner1.png';
import partner2 from '../../../assets/partner2.png';    
import partner3 from '../../../assets/partner3.png';
import partner4 from '../../../assets/partner4.png';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./Partners.css";

// import required modules
import { Autoplay, Navigation } from "swiper";

const Partners = () => {
    return (        
        <div className='partner-details'>
            <h1 className="partner-title">our PARTNERS</h1>
            <Swiper 
                // navigation={true}
                modules={[Autoplay, Navigation]} 
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: true,
                }}
                className="mySwipers"
            >
                <SwiperSlide>
                    <div className="partner-swiper">
                        <div className='partner-img'><img src={partner1} alt="partner1" /></div>
                        <div className='partner-img'><img src={partner2} alt="partner2" /></div>  
                        <div className='partner-img'><img src={partner3} alt="partner3" /></div>
                        <div className='partner-img'><img src={partner4} alt="partner4" /></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="partner-swiper">
                        <div className='partner-img'><img src={partner1} alt="partner1" /></div>
                        <div className='partner-img'><img src={partner2} alt="partner2" /></div>  
                        <div className='partner-img'><img src={partner3} alt="partner3" /></div>
                        <div className='partner-img'><img src={partner4} alt="partner4" /></div>
                    </div>
                </SwiperSlide>
            </Swiper>
        
        </div>
    )
}

export default Partners;