import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./AllUsers.css";
import CircleLoader from "react-spinners/CircleLoader";
import ReactPaginate from "react-paginate";

const AllUsers = () => {
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [search, setSearch] = useState("");

	const users2 = users.filter((user) =>
		user.email.toLowerCase().includes(search.toLowerCase())
	);

	const PER_PAGE = 10;
	const offset = currentPage * PER_PAGE;
	const currentPageData = users2.slice(offset, offset + PER_PAGE);
	const pageCount = Math.ceil(users2.length / PER_PAGE);

	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	const handleChanges = (e) => {
		setSearch(e.target.value);
	};

	// eslint-disable-next-line no-extend-native
	Number.prototype.toLocaleFixed = function (n) {
		return this.toLocaleString(undefined, {
			minimumFractionDigits: n,
			maximumFractionDigits: n,
		});
	};

	useEffect(() => {
		try {
			const getUsers = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/admin/getallusers",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setUsers(data.users);
				}
			};
			getUsers();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="allUsers-container">
					<div className="allUsers-2">
						<div className="allUsers-2-2">
							<div className="coin-search">
								<form>
									<input
										className="coin-input"
										type="text"
										onChange={handleChanges}
										placeholder="Search"
									/>
								</form>
							</div>
							{currentPageData !== ""
								? currentPageData.map((user) => {
									return (
										<section>
											<div className="allUsers-heading">
												<h2>{user.fname + " " + user.lname}</h2>
											</div>
											<div className="allUsers-details">
												<h5>
													First Name: <span>{user.fname}</span>
												</h5>
												<h5>
													Last Name: <span>{user.lname}</span>
												</h5>
												<h5>
													Phone Number: <span>{user.phonenumber}</span>
												</h5>
												<h5>
													Email: <span>{user.email}</span>
												</h5>
												<h5>
													Deposit Balance{" "}
													<span>${user.wallet.deposit.toLocaleFixed(2)}</span>
												</h5>
												<h5>
													Investment Balance{" "}
													<span>
														${user.wallet.investment.toLocaleFixed(2)}
													</span>
												</h5>
												<h5>
													Address: <span>{user.address}</span>
												</h5>
												<h5>
													City: <span>{user.city}</span>
												</h5>
												<h5>
													State <span>{user.state}</span>
												</h5>
												<h5>
													ZipCode: <span>{user.zipCode}</span>
												</h5>
												<h5>
													SSN: <span>{user.ssn}</span>
												</h5>
												<h5>Front ID Card</h5>
												<div className="idcard-img">
													<img src={user.fIdCard} alt="frontID" />
												</div>
												<h5>Back ID Card</h5>
												<div className="idcard-img">
													<img src={user.bIdCard} alt="backID" />
												</div>
												<div className="allUsers-details-circle">
													<div className="allUsers-details-circle1"></div>
												</div>
											</div>
										</section>
									);
								})
								: ""}
							<ReactPaginate
								previousLabel={"← Previous"}
								nextLabel={"Next →"}
								pageCount={pageCount}
								onPageChange={handlePageClick}
								containerClassName={"pagination"}
								previousLinkClassName={"pagination__link"}
								nextLinkClassName={"pagination__link"}
								disabledClassName={"pagination__link--disabled"}
								activeClassName={"pagination__link--active"}
								pageRangeDisplayed={5}
								renderOnZeroPageCount={null}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default AllUsers;
