import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "./Signin.css";
import { useNavigate, Link } from "react-router-dom";
import heroImg from "../../assets/signin.png";
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import Cookies from "js-cookie";

const BasicForm = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const navigate = useNavigate();
	const cookies = Cookies.get("jwt");

	useEffect(() => {
		const verifyUser = async () => {
			if (!cookies) {
				navigate("/signin");
			}
			const { data } = await axios.post(
				"https://mergingtradingllc.com/api/verified",
				{},
				{
					withCredentials: true,
				}
			);
			if (data.success === true) {
				navigate("/dashboard/home");
			}
		};
		verifyUser();
	}, [loading, cookies, navigate]);

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const { data } = await axios.post(
				"https://mergingtradingllc.com/api/signin",
				{
					email: values.email,
					password: values.password,
				}
			);

			const showToastMessage = () => {
				toast.info(data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (data.success === true) {
				actions.resetForm();
				var inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
				Cookies.set("jwt", data.token, { expires: inOneHour });
				setTimeout(() => {
					navigate("/dashboard/home");
				}, 1000);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(4, 7);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const basicSchema = yup.object().shape({
		email: yup
			.string()
			.email("Please enter a valid email")
			.required("Required"),
		password: yup.string().min(5).required("Required"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	// console.log(errors);

	return (
		<>
			{loading ? (
				<div className="signup-loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="signin-wrapper">
					<form onSubmit={handleSubmit} autoComplete="off">
						<div className="signin-heading">
							<h2>Sign In</h2>
							<p>Enter your email and password to login</p>
						</div>

						<label htmlFor="email">Email</label>
						<input
							id="email"
							type="email"
							placeholder="Enter your email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							className={errors.email && touched.email ? "input-error" : ""}
						/>
						{errors.email && touched.email && (
							<p className="error">{errors.email}</p>
						)}

						<label htmlFor="password">Password</label>
						<input
							id="password"
							type="password"
							placeholder="Enter your password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							className={
								errors.password && touched.password ? "input-error" : ""
							}
						/>
						{errors.password && touched.password && (
							<p className="error">{errors.password}</p>
						)}

						<Link to="/recovery"> Forgot Password?</Link>

						<button disabled={isSubmitting} type="submit">
							{loading2 ? (
								<ScaleLoader
									className="loader-icon"
									color={"rgb(255, 255, 255)"}
									loading={loading2}
									size={50}
									speedMultiplier={1}
								/>
							) : (
								"Submit"
							)}
						</button>

						<p>
							Don't have an account? <Link to="/signup"> Sign Up </Link>
						</p>
					</form>
					<div className="sign-hero-2">
						<img src={heroImg} alt="hero-section-img" />
					</div>
				</div>
			)}
		</>
	);
};
export default BasicForm;
