import React, {useState,useEffect} from 'react';
import "./Hero.css";
import playstore from "../../../assets/playstore.png";
import appstore from "../../../assets/appstore.png";
import heroImg from "../../../assets/Asset1.png";
import { toast } from 'react-toastify';
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particlesConfig from "./particle-config";

const Hero = () => {
    const [writeText, setWriteText] = useState("")
    const [index, setIndex] = useState(1)

    useEffect(() => {
        textUpdateHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[index])

    function textUpdateHandler() {
        let text = "WELCOME TO MERGING TRADER";
        setTimeout(() => {
            setWriteText(text.slice(0, index))
            setIndex(index + 1)
            if(index > text.length) {
                setIndex(1)
            }
        }, 200)
    }

    //toastify for Download Buttons
    const showToastMessage = () => {
        toast.info('Coming soon...', {
            position: toast.POSITION.TOP_RIGHT,
            className: 'toast-message'
        });
    };

    //tsParticles Functions
    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        // await console.log(container);
    }, []);

    return (
        <div className='hero-wrapper'>
            <Particles
                className='tsparticles-hero'
                init={particlesInit}
                loaded={particlesLoaded}
                options={particlesConfig}
            />
            <div className='hero'>
                <div className='hero-1'>
                    <h3>{writeText}</h3>
                    <div>
                        <h1>Investing for the </h1>
                        <h1>Bright Future</h1>
                    </div>
                    <p>Merging Trader is an investment company, whose team is working to make money from the volatility of cryptocurrencies and offer great return to our clients.</p>
                    <div className='download-btns'>
                        <div className='download-btn' >
                            <img src={playstore} alt="playstore" onClick={showToastMessage}/>
                        </div>
                        <div className='download-btn' >
                            <img src={appstore} alt="appstore" onClick={showToastMessage}/>
                        </div>
                    </div>
                </div>
                <div className='hero-2'>
                    <a href="/#"><img src={heroImg} alt="hero-section-img" /></a>
                </div>
            </div>
        </div>
    )};

export default Hero;