import React, {useState, useEffect} from 'react';
import './Coin.css';
import {IoIosArrowDown} from "react-icons/io";
import {IoIosArrowUp} from "react-icons/io";
import ScaleLoader from "react-spinners/ScaleLoader";

const Coin = ({name, price, symbol, image, priceChange, onClickFunction}) => {
  const [loading2, setLoading2] = useState(false);
  const [coinAmount, setCoinAmount] = useState("");

  function coinAmountHandler(amount) {
    let coinEquivalent = amount / price 
    setCoinAmount(coinEquivalent);
  }

  //Dropdown State Wallet
    const [isToggled, setIsToggled] = useState(false);
    const openFeatureToggler = () => {
      setIsToggled(!isToggled);
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    //Wallet State Handler
    const walletInitials = {amount: ""};
    const [wallet, setWallet] = useState(walletInitials);
    const handleWalletChange= (e) => {
      const {name, value} = e.target;
      
      setWallet(values => ({
        ...values,
        [name]: value
      }));
      coinAmountHandler(value);
    }
    const handleWalletSubmit = (event) => {
      event.preventDefault();
      // console.log(wallet);
      setLoading2(true);
      setTimeout(()=> {
        setLoading2(false);
      }, 950)
      setTimeout(()=> {
        setWallet(walletInitials);
        setCoinAmount("")
      }, 1000)
      onClickFunction();
    }

  return (
    <div className='coin-container' >
      <div className='coin-containers'>
        <div className='coin-row'>
          <div className='coin-img'>
            <img src={image} alt='crypto' />
          </div>
          <div className='coin-name'>
            <h5>{name}</h5>
            <p className='coin-symbol'>{symbol}</p>
          </div>
          <div className='coin-data'>
            <p className='coin-price'>${price.toLocaleString()}</p>
            {priceChange < 0 ? (
              <p className='coin-percent red'>{priceChange.toFixed(2)}%</p>
            ) : (
              <p className='coin-percent green'>{priceChange.toFixed(2)}%</p>
            )}
          </div>
        </div>
          { !isToggled ? <IoIosArrowDown className='coin-icon' onClick={openFeatureToggler}/> : <IoIosArrowUp className='coin-icon' onClick={openFeatureToggler}/>} 
      </div>

      <div className="coin-section">
        <div className='coin-section-1' onClick={openFeatureToggler}>
          <h5>Buy {name}</h5>
        </div>
        <div className={`toggle-menu ${isToggled ? "active" : ""}`}>
          <form onSubmit={handleWalletSubmit} >
            <label>USD Amount</label>
            <input
              name="amount"
              value={wallet.amount}
              onChange={handleWalletChange}
              placeholder="Enter amount in USD"
              type="number"
              min="1" 
              max="1000000000000"
              step="0.01"
              autoComplete="off"
              required
            />
            <label>{symbol.toUpperCase()} Amount</label>
            <div className='coin-amount-container'>{coinAmount}</div>
            <button type="submit">
              {loading2 ? <ScaleLoader className="loader-icon" color={"rgb(230, 232, 235)"} loading={loading2} size={50} speedMultiplier={1} />: "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Coin;
